import React from "react";
import { Helmet } from "react-helmet";

const WebsiteTitle = ({ title }) => {
  return (
    <Helmet>
      <title>{title?.length ? "BAP | " + title : "Bidster Admin Panel"}</title>
    </Helmet>
  );
};

export default WebsiteTitle;
