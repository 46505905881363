import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Table, Rate, Radio, Checkbox, Button, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import { DownOutlined, StarOutlined } from "@ant-design/icons";
import {
	getAllService,
	postAllProsAccountsWithDetails,
	assignProsToSpecificProject,
} from "../network";
import { toastError, toastSuccess } from "helpers/toasters";
import { searchOnTable } from "helpers/searchOnTable";
import { ServiceTypePreferancesModal } from "../components/ServiceTypePreferancesModal";
import { ProRatingAndReviews } from "../components/ProRatingAndReviews";
import RatingTableFilter from "modules/Shared/TableRatingFilter";
import CustomTooltip from "modules/components/CustomTooltip";
import ProjectsModal from "../components/ProjectsModal";
import ActivateUserModal from "../components/ActivateUserModal";
import moment from "moment";
import SearchInTable from "modules/components/SearchInTable";
import ProPortofolioModal from "../components/ProPortofolioModal";
import GrantModal from "../components/GrantModal";
import coinsIcon from "assets/Icons/coins.svg";
import Moment from "moment";

const PropertyOwner = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [pros, setPros] = useState([]);
	const [showingData, setShowingData] = useState([]);
	const [isProjectModalVisible, setIsProjectModalVisible] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [isActiveUserModalVisible, setIsActiveUserModalVisible] =
		useState(false);
	const [services, setServices] = useState([]);
	const [searchVal, setSearchVal] = useState("");
	const [isDisabled, setIsDisabled] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [openProPortofolio, setOpenProPortofolio] = useState(false);
	// user => Disabled users after assign project to them
	const [isDisabledAssignedUsers, setIsDisabledAssignedUsers] = useState([]);
	const [isGrantModalOpened, setIsGrantModalOpened] = useState(false);
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);
	const [isOpenServiceTypePreferences, setIsOpenServiceTypePreferences] =
		useState(false);
	const [isOpenRatingModal, setIsOpenRatingModal] = useState(false);
	const { t } = useTranslation();
	const { state } = useLocation();

	function getAllPros() {
		setIsLoading(true);
		setSearchVal("");
		if (state?.projectId) {
			postAllProsAccountsWithDetails(
				state?.projectId,
				{
					fromDate,
					toDate,
				},
				(success) => {
					setIsLoading(false);
					setPros(success.data);
					setShowingData(success.data);
				},
				(fail) => {
					setIsLoading(false);
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		} else {
			postAllProsAccountsWithDetails(
				null,
				{
					fromDate,
					toDate,
				},
				(success) => {
					setIsLoading(false);
					setPros(success.data);
					setShowingData(success.data);
				},
				(fail) => {
					setIsLoading(false);
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		}
	}

	useEffect(() => {
		getAllPros();

		getAllService(
			(success) => {
				success.data.forEach((item) => {
					setServices((prev) => [
						...prev,
						{ text: item.name, value: item._id },
					]);
				});
			},
			(fail) => {
				return;
			}
		);
		// eslint-disable-next-line
	}, [fromDate, toDate]);

	useEffect(() => {
		setIsDisabled(isLoading || !selectedUsers.length);
	}, [isLoading, selectedUsers]);

	const columns = [
		{
			title: t("name"),
			dataIndex: "userName",
			key: "userName",
			align: "center",
			width: 200,
			fixed: "left",
			render: (data, userData) => {
				return (
					<div
						role="button"
						onClick={() => {
							setSelectedUser(userData);
							setIsOpenServiceTypePreferences(true);
						}}
						style={{
							textDecoration: "underline",
						}}
					>
						<CustomTooltip text={data} count={25} />
					</div>
				);
			},
		},
		{
			title: t("email"),
			dataIndex: "userEmail",
			key: "userEmail",
			align: "center",
			width: 200,
			render: (data) => {
				return <CustomTooltip text={data} count={25} />;
			},
		},
		{
			title: t("mobile"),
			dataIndex: "userPhone",
			key: "userPhone",
			align: "center",
			width: 200,
			render: (data) => {
				return <CustomTooltip text={data} count={25} />;
			},
		},
		{
			title: t("service"),
			dataIndex: "service",
			key: "service",
			align: "center",
			width: 150,
			filters: services,
			filterIcon: () => <DownOutlined />,
			onFilter: (value, record) => record.serviceId === value,
		},
		{
			title: t("projects"),
			dataIndex: "projects",
			key: "projects",
			align: "center",
			width: 100,
			sorter: (a, b) => a.projects.length - b.projects.length,
			render: (data, record) => {
				return (
					<div
						style={{
							textDecoration: data.length && "underline",
						}}
						role={"button"}
						onClick={() => {
							if (data.length) {
								openProjectsModal(record);
							}
						}}
					>
						{data.length}
					</div>
				);
			},
		},
		{
			title: t("status"),
			dataIndex: "status",
			key: "status",
			align: "center",
			width: 100,
			filters: [
				{ text: "Active", value: 1 },
				{ text: "In Active", value: 0 },
			],
			filterIcon: () => <DownOutlined />,
			onFilter: (value, record) => record.status === value,
			render: (status) => {
				return (
					<>
						{
							<>
								{status ? (
									<strong>{t("active")}</strong>
								) : (
									<div
										style={{
											color: "#707070",
										}}
									>
										{t("inActive")}
									</div>
								)}
							</>
						}
					</>
				);
			},
		},
		{
			title: () => (
				<div
					style={{
						display: "flex",
						alignItems: "baseline",
						justifyContent: "center",
					}}
				>
					<div>Used</div>
					<img
						style={{ width: "1rem", marginInlineStart: "0.5rem" }}
						src={coinsIcon}
						alt="coins"
					/>
				</div>
			),
			dataIndex: "usedTokens",
			key: "usedTokens",
			align: "center",
			width: 100,
			sorter: (a, b) => a.usedTokens - b.usedTokens,
		},
		{
			title: () => (
				<div
					style={{
						display: "flex",
						alignItems: "baseline",
						justifyContent: "center",
					}}
				>
					<div>Purchased</div>
					<img
						style={{ width: "1rem", marginInlineStart: "0.5rem" }}
						src={coinsIcon}
						alt="coins"
					/>
				</div>
			),
			dataIndex: "purchasedTokens",
			key: "purchasedTokens",
			align: "center",
			width: 100,
			sorter: (a, b) => a.purchasedTokens - b.purchasedTokens,
		},
		{
			title: () => (
				<div
					style={{
						display: "flex",
						alignItems: "baseline",
						justifyContent: "center",
					}}
				>
					<div>Granted</div>
					<img
						style={{ width: "1rem", marginInlineStart: "0.5rem" }}
						src={coinsIcon}
						alt="coins"
					/>
				</div>
			),
			dataIndex: "coinsGrantedByAdmin",
			key: "coinsGrantedByAdmin",
			align: "center",
			width: 100,
			visible: false,
			sorter: (a, b) => a.coinsGrantedByAdmin - b.coinsGrantedByAdmin,
			render: (data, user) => (
				<div
					role="button"
					style={{
						textDecoration: "underline",
					}}
					onClick={() => GrantUser(user)}
				>
					{data}
				</div>
			),
		},
		{
			title: () => (
				<div
					style={{
						display: "flex",
						alignItems: "baseline",
						justifyContent: "center",
					}}
				>
					<div>Credit</div>
					<img
						style={{ width: "1rem", marginInlineStart: "0.5rem" }}
						src={coinsIcon}
						alt="coins"
					/>
				</div>
			),
			dataIndex: "credit",
			key: "credit",
			align: "center",
			width: 100,
			sorter: (a, b) => a.credit - b.credit,
		},
		{
			title: t("signUpDate"),
			dataIndex: "signUpDate",
			key: "signUpDate",
			align: "center",
			sorter: (a, b) =>
				new Moment(a.signUpDate).format("YYYYMMDD") -
				new Moment(b.signUpDate).format("YYYYMMDD"),
			render: (date, a) => {
				return <>{moment(date).format("DD-MM-YYYY")}</>;
			},
		},
		{
			title: t("rating"),
			dataIndex: "rating",
			key: "rating",
			align: "center",
			render: (rate, user) => {
				return (
					<div role="button" onClick={() => openRatingModal(user)}>
						<Rate
							style={{
								color: "#12819c",
								border: "none",
							}}
							allowHalf
							disabled
							character={<StarOutlined />}
							defaultValue={rate}
						/>
					</div>
				);
			},
			...RatingTableFilter(pros, setShowingData, "rating"),
		},
		{
			title: t("validation"),
			dataIndex: "complite",
			key: "complite",
			align: "center",
			filters: [
				{ text: t("empty"), value: null },
				{ text: t("validate"), value: 0 },
				{ text: t("complete"), value: 1 },
			],
			filterIcon: () => <DownOutlined />,
			onFilter: (value, record) => record.complite === value,
			render: (complite, proData) => {
				return (
					<>
						{complite === null ? (
							<div className="danger-color">{t("empty")}</div>
						) : complite === 0 ? (
							<div
								className="warn-color underline"
								role={"button"}
								onClick={() => validatePro(proData)}
							>
								{t("validate")}
							</div>
						) : (
							<div
								className="main-color underline"
								role={"button"}
								onClick={() => validatePro(proData)}
							>
								<strong>{t("complete")}</strong>
							</div>
						)}
					</>
				);
			},
		},
		{
			title: t("activity"),
			dataIndex: "isUserActive",
			key: "isUserActive",
			align: "center",
			width: 50,
			filters: [
				{ text: "Actived", value: true },
				{ text: "Di-activated", value: false },
			],
			filterIcon: () => <DownOutlined />,
			onFilter: (value, record) => record.isUserActive === value,
			render: (isActive, userData) => {
				return (
					<Radio
						checked
						onClick={() => openActivateUser(userData)}
						className={!isActive ? "in-active-radio" : "active-radio"}
					/>
				);
			},
		},
	];

	if (state?.projectId) {
		columns.splice(-1, 0, {
			title: t("assign"),
			key: "assigned",
			dataIndex: "assigned",
			align: "center",
			render: (_, record) => {
				return (
					<Checkbox
						value={record.userId}
						defaultChecked={
							record.assigned || isDisabledAssignedUsers.includes(record.userId)
						}
						disabled={
							record.assigned || isDisabledAssignedUsers.includes(record.userId)
						}
						onChange={(e) => changeAssignChecked(e)}
					/>
				);
			},
		});
	}

	function validatePro(proData) {
		setOpenProPortofolio(true);
		setSelectedUser(proData);
	}

	function changeAssignChecked(e) {
		const { value: userId } = e.target;

		setSelectedUsers((prev) => {
			let users = [];
			let selectedUser = prev.find((user) => user === userId);

			if (selectedUser === undefined) {
				users = [...prev, userId];
			} else {
				users = prev.filter((user) => userId !== user);
			}
			return users;
		});
	}

	function openProjectsModal(user) {
		setSelectedUser(user);
		setIsProjectModalVisible(true);
	}

	function openActivateUser(user) {
		setIsActiveUserModalVisible(true);
		setSelectedUser(user);
	}

	function assingProjectToPros() {
		assignProsToSpecificProject(
			selectedUsers,
			state.projectId,
			(success) => {
				toastSuccess(success.message);
				setIsDisabledAssignedUsers((prev) => [prev, ...selectedUsers]);
				setSelectedUsers([]);
				getAllPros();
			},

			(fail) => {
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	function GrantUser(user) {
		setSelectedUser(user);
		setIsGrantModalOpened(true);
	}

	function changeDateFilter(date) {
		if (!date) {
			setFromDate(null);
			setToDate(null);
			return;
		}
		const [from, to] = date;
		setFromDate(moment(from).toISOString());
		setToDate(moment(to).toISOString());
	}

	function openRatingModal(value) {
		setIsOpenRatingModal(true);
		setSelectedUser(value);
	}

	return (
		<div>
			<header className="main-header">
				<h2>Pros</h2>
			</header>

			<div
				className="search-date filter"
				style={{
					display: "flex",
					alignItems: "flex-start",
					justifyContent: "space-between",
				}}
			>
				<div className="search-date__signup-date">
					<div>Filter By Sign Up:</div>
					<DatePicker.RangePicker
						className="date"
						onChange={changeDateFilter}
						format={(value) => moment(value).format("DD-MM-YYYY")}
						size="middle"
					/>
				</div>
				<div style={{ display: "flex", justifyContent: "flex-start" }}>
					<SearchInTable
						className="search-date__search"
						searchVal={searchVal}
						setSearchVal={setSearchVal}
						onSubmit={() =>
							searchOnTable(
								searchVal,
								"userName",
								setIsLoading,
								pros,
								setShowingData
							)
						}
					/>

					{state?.projectId && (
						<div className="filters__wrapper">
							<Button
								className={`bap-button`}
								type="primary"
								onClick={() => assingProjectToPros()}
								disabled={isDisabled}
							>
								Assign
							</Button>

							<div
								style={{
									textAlign: "center",
									marginInline: "auto",
								}}
								className={`filters__assign`}
							>
								{/* Assigned pros number on (project name) is “number” */}
								Assigned pros number on (
								<CustomTooltip count={10} text={state?.projectName} /> ) is{" "}
								<strong>{selectedUsers.length}</strong>
							</div>
						</div>
					)}
				</div>
			</div>

			<Table
				dataSource={showingData}
				columns={columns}
				rowKey={(record) => record.userId}
				bordered
				loading={isLoading}
				size={"middle"}
				scroll={{
					x: "max-content",
					scrollToFirstRowOnChange: true,
				}}
				pagination={{
					pageSize: 10,
				}}
			/>

			{isActiveUserModalVisible && (
				<ActivateUserModal
					visible={isActiveUserModalVisible}
					onCancel={() => setIsActiveUserModalVisible(false)}
					user={selectedUser}
					setUsers={setPros}
				/>
			)}

			{isProjectModalVisible && (
				<ProjectsModal
					visible={isProjectModalVisible}
					onCancel={() => setIsProjectModalVisible(false)}
					projects={selectedUser.projects}
					userName={selectedUser.userName}
				/>
			)}

			{openProPortofolio && (
				<ProPortofolioModal
					isVisible={openProPortofolio}
					onCancel={() => {
						setOpenProPortofolio(false);
						setSelectedUser(null);
					}}
					proId={selectedUser.userId}
					getAllPros={getAllPros}
				/>
			)}
			{isGrantModalOpened && (
				<GrantModal
					isVisible={isGrantModalOpened}
					onCancel={() => setIsGrantModalOpened(false)}
					setAllShowenData={setShowingData}
					setPros={setPros}
					proId={selectedUser.userId}
				/>
			)}
			{isOpenServiceTypePreferences && (
				<ServiceTypePreferancesModal
					isVisible={isOpenServiceTypePreferences}
					onCancel={() => setIsOpenServiceTypePreferences(false)}
					proId={selectedUser.userId}
				/>
			)}
			{isOpenRatingModal && (
				<ProRatingAndReviews
					isVisible={isOpenRatingModal}
					onCancel={() => setIsOpenRatingModal(false)}
					proId={selectedUser.userId}
				/>
			)}
		</div>
	);
};

export default PropertyOwner;
