import { createSlice } from "@reduxjs/toolkit";

export const authorizationSlice = createSlice({
	name: "authorization",
	initialState: {
		authorization: {},
	},
	reducers: {
		login: (state, action) => {
			state.authorization = action.payload;
		},
		logout: (state, action) => {
			state.authorization = {};
		},
	},
});

const { reducer, actions } = authorizationSlice;
export const { login, logout } = actions;
export default reducer;
