export function searchOnTable(
	searchVal,
	field,
	setIsLoading,
	data,
	setShowingData
) {
	// search on table
	let regex = new RegExp(searchVal, "gi");
	setIsLoading(true);
	if (searchVal === "") {
		setShowingData(data);
		setIsLoading(false);
	} else {
		setShowingData(() => {
			let dataToFilter = JSON.parse(JSON.stringify(data));
			let filteredData = dataToFilter.filter((item) => {
				if (item[field].match(regex)?.length) {
					return item;
				} else return null;
			});
			setIsLoading(false);
			// return JSON.parse(JSON.stringify(filteredData));
			return [...filteredData];
		});
	}
}
