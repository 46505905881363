import React from "react";
import { useState, useEffect } from "react";
import { Form, Input, Button, Radio, Switch } from "antd";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import plus from "assets/Icons/plus.svg";
import {
	deactiveteQandA,
	getQAndA,
	editQAndA,
	deleteQAndAAPI,
	addQAndAs,
} from "modules/Pages/PagesModal/network";
import { toastError, toastSuccess } from "helpers/toasters";
import TextArea from "antd/lib/input/TextArea";

function QAndA() {
	const [enQuestion, setEnQuestion] = useState("");
	const [arQuestion, setArQuestion] = useState("");
	const [enAnswer, setEnAnswer] = useState("");
	const [arAnswer, setArAnswer] = useState("");
	const [loadingDelete, updateLoadingDelete] = useState(false);
	const [loadingSave, updateLoadingSave] = useState(false);
	const [allData, updateAllData] = useState(null);
	const [sliderIndex, updateSliderIndex] = useState(0);
	const [indexList, updateIndexList] = useState([]);
	const [sortValue, setSortValue] = useState(1);
	const [isActive, updateIsActive] = useState(false);
	const { t } = useTranslation();
	const getAllUtls = (sliderIndexVar) => {
		getQAndA(
			(success) => {
				let data = success.data[sliderIndexVar > 0 ? sliderIndexVar : 0];
				updateAllData(data);
				setEnQuestion(data.question.en);
				setArQuestion(data.question.ar);
				setEnAnswer(data.answer.en);
				setArAnswer(data.answer.ar);
				updateIsActive(data.isActive);
				updateIndexList(success.data);
				setSortValue(data.index);
			},
			(fail) => {}
		);
	};

	useEffect(() => {
		getAllUtls();
		// eslint-disable-next-line
	}, []);

	const deleteQAndA = () => {
		let data = { faqId: allData._id };
		updateLoadingDelete(true);

		deleteQAndAAPI(
			data,
			(success) => {
				updateLoadingDelete(false);
				toastSuccess(success.message);
				getAllUtls(sliderIndex - 1);
			},
			(fail) => {
				updateLoadingDelete(false);

				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	};

	const addQAndA = () => {
		let indexListVar = [...indexList];
		let newQandA = {
			index: indexListVar.length,
			isActive: true,
			question: { en: "", ar: "" },
			answer: { en: "", ar: "" },
		};
		indexListVar.push(newQandA);
		updateIndexList(indexListVar);
		updateSliderIndex(indexListVar.length - 1);
		setSortValue(indexListVar.length - 1);
	};

	useEffect(() => {
		if (indexList.length) {
			setEnQuestion(indexList[sliderIndex].question.en);
			setArQuestion(indexList[sliderIndex].question.ar);
			setEnAnswer(indexList[sliderIndex].answer.en);
			setArAnswer(indexList[sliderIndex].answer.ar);
			updateIsActive(indexList[sliderIndex].isActive);
			setSortValue(indexList[sliderIndex].index);
			updateAllData(indexList[sliderIndex]);
		}
		// eslint-disable-next-line
	}, [sliderIndex]);
	const onFinish = (values) => {
		let data = {
			...values,
		};
		let modifiedData = {
			"question.en": data.enQuestion,
			"question.ar": data.arQuestion,
			"answer.en": data.enAnswer,
			"answer.ar": data.arAnswer,
			index: sortValue,
			faqId: allData?._id ? allData._id : null,
		};
		updateLoadingSave(true);
		if (modifiedData.faqId) {
			editQAndA(
				modifiedData,
				(success) => {
					updateLoadingSave(false);
					toastSuccess(success.message);
					// getAllUtls();
				},
				(fail) => {
					updateLoadingSave(false);
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		} else {
			addQAndAs(
				modifiedData,
				(success) => {
					updateLoadingSave(false);
					toastSuccess(success.message);
					getAllUtls(sliderIndex);
				},
				(fail) => {
					updateLoadingSave(false);
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		}
	};

	const onRadioChange = (e) => {
		setSortValue(e.target.value);
	};
	const handleToggleQAndA = (value) => {
		let data = { faqId: allData._id, isActive: value };
		deactiveteQandA(
			data,
			(success) => {
				if (success.isSuccess) {
					updateIsActive(value);
				}
				toastSuccess(success.message);
			},
			(fail) => {}
		);
	};

	return (
		<div className="qAndA d-flex">
			{" "}
			<div className="utlsAndMore d-flex flex-1 flex-column">
				<div className="d-flex justify-content-between">
					<Switch
						checked={isActive}
						checkedChildren="ON"
						unCheckedChildren="OFF"
						onChange={handleToggleQAndA}
					/>
					<Dropdown>
						<Dropdown.Toggle variant="success" id="dropdown-basic">
							Index
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Radio.Group onChange={onRadioChange} value={sortValue}>
								{indexList.map((indexItem, indexNumber) => {
									return (
										<Dropdown.Item key={indexNumber}>
											<Radio value={indexNumber}>
												<div
													onClick={() => {
														updateSliderIndex(indexNumber);
													}}
													className={
														sliderIndex === indexNumber ? "text-underline" : ""
													}
												>
													{indexNumber + 1}
												</div>
											</Radio>
										</Dropdown.Item>
									);
								})}
							</Radio.Group>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<Form
					onFinish={onFinish}
					name="basic"
					initialValues={{
						enQuestion,
						arQuestion,
						enAnswer,
						arAnswer,
					}}
					key={allData?.question?.en || indexList.length}
				>
					<Form.Item
						name="enQuestion"
						colon={false}
						required
						requiredMark={false}
						rules={[
							{
								required: true,
								message: "Please, Enter the English Question",
							},
						]}
					>
						<Input
							className="bap-input"
							placeholder={`Question ${sliderIndex + 1}`}
							value={enQuestion}
							onChange={(e) => setEnQuestion(e.target.value)}
						/>
					</Form.Item>

					<Form.Item
						name="arQuestion"
						colon={false}
						required
						requiredMark={false}
						rules={[
							{
								required: true,
								message: "Please, Enter the Arabic Question",
							},
						]}
					>
						<Input
							style={{
								direction: "rtl",
							}}
							className="bap-input"
							value={arQuestion}
							onChange={(e) => setArQuestion(e.target.value)}
							placeholder={`السؤال ${sliderIndex + 1}`}
						/>
					</Form.Item>

					<Form.Item
						name="enAnswer"
						colon={false}
						required
						requiredMark={false}
						rules={[
							{
								required: true,
								message: "Please, Enter the English Answer",
							},
						]}
					>
						<TextArea
							style={{ resize: "none" }}
							className="bap-input"
							value={enAnswer}
							onChange={(e) => setEnQuestion(e.target.value)}
							placeholder={`Answer ${sliderIndex + 1}`}
						/>
					</Form.Item>

					<Form.Item
						name="arAnswer"
						colon={false}
						required
						requiredMark={false}
						rules={[
							{
								required: true,
								message: "Please, Enter the Arabic Answer",
							},
						]}
					>
						<TextArea
							style={{ resize: "none", direction: "rtl" }}
							className="bap-input"
							value={enAnswer}
							onChange={(e) => setArQuestion(e.target.value)}
							placeholder={`الاجابه ${sliderIndex + 1}`}
						/>
					</Form.Item>
					<Form.Item>
						<div className="controllings-Buttons">
							<Button
								htmlType="submit"
								type="primary"
								className="bap-primary-button bap-button"
								loading={loadingSave}
							>
								{t("save")}
							</Button>
							<Button
								onClick={deleteQAndA}
								className="bap-primary-button bap-button"
								loading={loadingDelete}
								disabled={indexList.length <= 1 || !allData?._id}
							>
								{t("delete")}
							</Button>
						</div>
					</Form.Item>
				</Form>
			</div>
			<div className="plus-container" onClick={addQAndA}>
				<img src={plus} alt="plus" onClick={addQAndA} />
			</div>
		</div>
	);
}

export default QAndA;
