import React, { useState, useEffect } from "react";
import { Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import UploadIcon from "assets/Icons/upload.svg";
import { baseUrl } from "services";
import {
	edithomeHowItWorks,
	getHowItWorks,
	uploadHowItWorksVideo,
} from "modules/Pages/PagesModal/network";
import { toastError, toastSuccess } from "helpers/toasters";

function LandingHowItWorks() {
	const { t } = useTranslation();
	const [enMedia, updateEnMedia] = useState(null);
	const [arMedia, updateArMedia] = useState(null);
	const [loadingSave, updateLoadingSave] = useState(false);
	const [loadingArMedia, updateLoadingArMedia] = useState(false);
	const [loadingEnMedia, updateLoadingEnMedia] = useState(false);
	const [mediaFormatAlert, updateMediaFormatAlert] = useState(false);
	const [allData, updateAllData] = useState(null);
	useEffect(() => {
		getHowItWorks(
			(success) => {
				updateEnMedia({
					path: success.data[0].video.en,
					name: success.data[0].video.en.split("-video-")[1],
				});
				updateArMedia({
					path: success.data[0].video.ar,
					name: success.data[0].video.ar.split("-video-")[1],
				});
				updateAllData(success.data[0]);
			},
			(fail) => {}
		);
	}, []);

	const handleUploadMedia = (e) => {
		const isVideo = e.target.files[0].type.includes("video");
		if (!isVideo) {
			updateMediaFormatAlert({
				place: e.target.id,
				msg: "Please, upload  video files only",
			});
		} else {
			if (e.target.id === "enMedia") {
				updateLoadingEnMedia(true);
			} else {
				updateLoadingArMedia(true);
			}
			let videoDataFile = new FormData();
			videoDataFile.append(
				"video",
				e.target.files[0],
				`-${e.target.files[0].type.split("/")[0]}-${e.target.files[0].name}`
			);

			uploadHowItWorksVideo(
				videoDataFile,
				(success) => {
					if (success.success) {
						if (e.target.id === "enMedia") {
							let fileData = Object.assign(e.target.files[0]);
							fileData.path = success.filePath;
							updateEnMedia(fileData);
						} else {
							let fileData = Object.assign(e.target.files[0]);
							fileData.path = success.filePath;
							updateArMedia(fileData);
						}
					}
					updateLoadingEnMedia(false);
					updateLoadingArMedia(false);
				},
				(fail) => {
					updateLoadingEnMedia(false);
					updateLoadingArMedia(false);
				}
			);
		}
	};
	const onFinish = (values) => {
		let data = {
			"video.en": enMedia.path,
			"video.ar": arMedia.path,
			howItWorksId: allData._id,
		};
		updateLoadingSave(true);
		edithomeHowItWorks(
			data,
			(success) => {
				toastSuccess(success.message);
				updateLoadingSave(false);
			},
			(fail) => {
				updateLoadingSave(false);
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	};

	return (
		<div className="howItWorks">
			<Form onFinish={onFinish} name="basic" key={allData}>
				<Form.Item
					name="enMedia"
					colon={false}
					valuePropName={enMedia}
					required
					key={mediaFormatAlert}
					requiredMark={false}
					rules={[
						{
							required: true,
							validator: (_, value) => {
								if (enMedia?.path) {
									return Promise.resolve();
								} else {
									return Promise.reject(
										new Error("Please, upload the English media")
									);
								}
							},
						},
					]}
				>
					<div className="bap-image-show d-flex align-items-center my-2">
						<a
							href={baseUrl + "/" + enMedia?.path}
							target="_blank"
							without
							rel="noreferrer"
						>
							{enMedia?.name}
						</a>
					</div>
					<input
						type="file"
						id="enMedia"
						className="d-none"
						onChange={handleUploadMedia}
					/>
					<Button
						loading={loadingEnMedia}
						className="bap-outline-button cursor-pointer m-0 p-0"
					>
						<label htmlFor="enMedia" className="cursor-pointer">
							<span>{t("uploadMediaEn")}</span>
							<img src={UploadIcon} alt="upload" />
						</label>
					</Button>
					{mediaFormatAlert?.place === "enMedia" && (
						<div className="text-danger">{mediaFormatAlert.msg}</div>
					)}
				</Form.Item>
				<Form.Item
					name="arMedia"
					colon={false}
					required
					requiredMark={false}
					rules={[
						{
							required: true,
							validator: (_, value) => {
								if (arMedia?.path) {
									return Promise.resolve();
								} else {
									return Promise.reject(
										new Error("Please, upload the Arabic Media")
									);
								}
							},
						},
					]}
				>
					<div className="bap-image-show d-flex align-items-center my-2">
						<a
							href={baseUrl + "/" + arMedia?.path}
							target="_blank"
							without
							rel="noreferrer"
						>
							{arMedia?.name}
						</a>
					</div>

					<input
						type="file"
						id="arMedia"
						className="d-none"
						onChange={handleUploadMedia}
					/>

					<div className="d-flex justify-content-end flex-1">
						<Button
							loading={loadingArMedia}
							className="bap-outline-button cursor-pointer m-0 p-0"
						>
							<label htmlFor="arMedia" className="cursor-pointer">
								<span>{t("uploadMediaAr")}</span>
								<img src={UploadIcon} alt="upload" />
							</label>
						</Button>
					</div>
					{mediaFormatAlert?.place === "arMedia" && (
						<div className="text-danger">{mediaFormatAlert.msg}</div>
					)}
				</Form.Item>

				<Form.Item>
					<div className="controllings-Buttons">
						<Button
							htmlType="submit"
							type="primary"
							className="bap-primary-button bap-button"
							loading={loadingSave}
						>
							{t("save")}
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
}

export default LandingHowItWorks;
