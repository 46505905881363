import { useEffect, useState } from "react";
import { Table, Rate, DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { StarOutlined, DownOutlined } from "@ant-design/icons";
import CustomTooltip from "modules/components/CustomTooltip";
import {
	getAllClientProjectDetailsWithFiltered,
	getAllService,
	getAllStatus,
	getOptionalServices,
} from "../network";
import SearchInTable from "modules/components/SearchInTable";
import { toastError } from "helpers/toasters";
import deleteIcon from "assets/Icons/delete.svg";
import deleteRedIcon from "assets/Icons/delete-red.svg";
import DeleteModal from "modules/components/DeleteModal";
import UsersModal from "../components/UsersModal";
import { useTranslation } from "react-i18next";
import { searchOnTable } from "helpers/searchOnTable";
import TableRatingFilter from "modules/Shared/TableRatingFilter";
import ROUTES from "constants/ROUTES";
import TableSearchFilter from "modules/Shared/TableSearchFilter";
import ProjectBrief from "../components/ProjectBrief";
const { RangePicker } = DatePicker;

const Projects = () => {
	const [projectsData, setProjectsData] = useState([]);
	const [showProjectsData, setShowProjectsData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [searchVal, setSearchVal] = useState("");
	const [filteredDate, setFilteredDate] = useState([null, null]);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const [isUsersModalVisible, setIsUsersModalVisible] = useState(false);
	const [selectedProject, setSelectedProject] = useState(null);
	const [statusesForFilter, setStatusesForFilter] = useState([]);
	const [services, setServices] = useState([]);
	const [isProjectPriefVisible, setIsProjectPriefVisible] = useState(null);
	const [optionalServices, setOptionalServices] = useState([]);
	const [isReloading, setIsReloading] = useState(false);
	const navigate = useNavigate();

	const { t } = useTranslation();

	useEffect(() => {
		setIsLoading(true);

		let payload = {
			status: [1, 2, 3, 4, 5, 6, 7, 8, 9],
			fromFilterDate: filteredDate[0],
			toFilterDate: filteredDate[1],
		};

		getAllClientProjectDetailsWithFiltered(
			payload,
			(success) => {
				setShowProjectsData([...success.data]);
				setProjectsData([...success.data]);
				setIsLoading(false);
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	}, [filteredDate, isReloading]);

	useEffect(() => {
		getAllService(
			(success) => {
				success.data.forEach((item) => {
					setServices((prev) => [
						...prev,
						{ text: item.name, value: item._id },
					]);
				});
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError();
				} else {
					toastError(fail?.data?.error);
				}
			}
		);

		getAllStatus(
			(success) => {
				success.data.forEach((item) => {
					setStatusesForFilter((prev) => [
						...prev,
						{ text: item.statusName, value: item.status },
					]);
				});
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError();
				} else {
					toastError(fail?.data?.error);
				}
			}
		);
	}, []);

	useEffect(() => {
		getOptionalServices(
			(success) => {
				// { text: item.name, value: item._id },
				let mappedOptionals = success.data.map((item) => ({
					text: item.optionalService,
					value: item._id,
				}));
				setOptionalServices(mappedOptionals);
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, []);

	function openBriefDetailsModal(project) {
		setIsProjectPriefVisible(true);
		setSelectedProject(project);
	}

	function openUsersModal(project) {
		setSelectedProject(project);
		setIsUsersModalVisible(true);
	}

	const columns = [
		{
			title: t("name"),
			dataIndex: "projectName",
			key: "projectName",
			align: "center",
			width: 200,
			fixed: "left",
			render: (projectName, project) => {
				return (
					<div
						style={{
							textDecoration: "underline",
						}}
						role={"button"}
						onClick={() => openBriefDetailsModal(project)}
					>
						{projectName}
					</div>
				);
			},
		},
		{
			title: t("service"),
			dataIndex: "serviceName",
			key: "serviceName",
			width: 200,
			fixed: "left",
			align: "center",
			filters: services,
			filterIcon: () => <DownOutlined />,
			onFilter: (value, record) => record.serviceId === value,
		},
		{
			title: t("client"),
			dataIndex: "clientName",
			key: "clientName",
			width: 200,
			align: "center",
		},
		{
			title: t("status"),
			dataIndex: "statusName",
			key: "statusName",
			width: 200,
			align: "center",
			filters: statusesForFilter,
			filterIcon: () => <DownOutlined />,
			onFilter: (value, record) => record.status === value,
			render: (statusName, project) => {
				return (
					<div
						role={"button"}
						onClick={() => openUsersModal(project)}
						style={{
							textDecoration: "underline",
						}}
					>
						{statusName}
					</div>
				);
			},
		},
		{
			title: t("bidders"),
			dataIndex: "biddersCount",
			key: "biddersCount",
			width: 200,
			align: "center",
			sorter: (a, b) => a.biddersCount - b.biddersCount,
		},
		{
			title: () => {
				return <>{t("submission")}</>;
			},
			dataIndex: "submissionDate",
			key: "submissionDate",
			width: 200,
			align: "center",
			render: (date) => {
				return <>{moment(date).format("DD-MM-YYYY")}</>;
			},
		},
		{
			title: "Time Frame",
			dataIndex: "timeFrame",
			key: "timeFrame",
			width: 200,
			align: "center",
			render: (data) => {
				return <>{data ? data : null}</>;
			},
		},
		{
			title: t("Hired"),
			dataIndex: "hiredUser",
			key: "hiredUser",
			width: 200,
			align: "center",
			render: (data) => {
				return <>{data ? data : " - "}</>;
			},
			filterSearch: true,
			...TableSearchFilter("hiredUser"),
		},
		{
			title: t("Rating"),
			dataIndex: "hiredUserRate",
			key: "hiredUserRate",
			width: 200,
			align: "center",
			render: (data) => {
				return (
					<Rate
						style={{
							color: "#12819c",
							border: "none",
						}}
						allowHalf
						disabled
						character={<StarOutlined />}
						defaultValue={data ? data : 0}
					/>
				);
			},
			...TableRatingFilter(projectsData, setShowProjectsData, "hiredUserRate"),
		},
		{
			title: t("addService"),
			dataIndex: "optionalServices",
			key: "optionalServices",
			width: 200,
			align: "center",
			filters: optionalServices,
			filterIcon: () => <DownOutlined />,
			onFilter: (value, record) =>
				record.optionalServices.filter((item) => item._id === value).length > 0,
			render: (optionalServices) => {
				return (
					<>
						{!optionalServices.length
							? " - "
							: optionalServices.map((item, idx) => (
									<span key={idx}>
										<CustomTooltip count={5} text={item.name} />
										{idx + 1 === optionalServices.length ? (
											""
										) : (
											<strong>, </strong>
										)}
									</span>
							  ))}
					</>
				);
			},
		},
		{
			title: t("assign"),
			width: 200,
			align: "center",
			render: (data, record) => {
				console.log(record.status);
				const isNotAssignable = [3, 4, 5, 8].includes(record.status);

				return (
					<div
						{...(!isNotAssignable && { role: "button" })}
						style={{
							textDecoration: "underline",
							opacity: isNotAssignable ? 0.5 : 1,
						}}
						onClick={() => {
							if (!isNotAssignable) {
								navigate(
									"/" +
										ROUTES.HOME +
										"/" +
										ROUTES.DASHBOARD +
										"/" +
										ROUTES.PROS,
									{
										state: {
											projectId: record.projectId,
											projectName: record.projectName,
										},
									}
								);
							}
						}}
					>
						Assign
					</div>
				);
			},
		},
		{
			title: () => {
				return <img src={deleteIcon} alt={"delete"} />;
			},
			dataIndex: "assign",
			key: "assign",
			width: 50,
			align: "center",
			render: (_, data) => {
				return (
					<img
						src={deleteRedIcon}
						alt={"true Project"}
						role={"button"}
						onClick={() => openDeleteModal(data)}
					/>
				);
			},
		},
	];
	function openDeleteModal(project) {
		setIsDeleteModalVisible(true);
		setSelectedProject(project);
	}

	function changeDateFilter(date) {
		console.log("hi there");
		setSearchVal(null);
		if (!date) {
			setFilteredDate([null, null]);
			return;
		}

		const [from, to] = date;
		const dateFormat = "YYYY-MM-DD";
		setFilteredDate([
			moment(from).format(dateFormat),
			moment(to).format(dateFormat),
		]);
	}

	return (
		<section className="main-section dashboard projects">
			<header className="main-header">
				<h2>Projects</h2>
			</header>

			<div className="search-date">
				<SearchInTable
					key={filteredDate}
					searchVal={searchVal}
					setSearchVal={setSearchVal}
					onSubmit={() =>
						searchOnTable(
							searchVal,
							"projectName",
							setIsLoading,
							projectsData,
							setShowProjectsData
						)
					}
					className="search-date__search"
				/>
				<RangePicker
					className="date"
					onChange={changeDateFilter}
					format={(value) => moment(value).format("DD-MM-YYYY")}
					size="middle"
				/>
			</div>

			<Table
				dataSource={[...showProjectsData]}
				columns={columns}
				rowKey={(record) => record.projectId}
				bordered
				size={"middle"}
				scroll={{
					x: "max-content",
					scrollToFirstRowOnChange: true,
				}}
				pagination={{
					pageSize: 8,
				}}
				loading={isLoading}
			/>
			{isProjectPriefVisible && (
				<ProjectBrief
					isVisible={isProjectPriefVisible}
					onCancel={() => setIsProjectPriefVisible(false)}
					projectId={selectedProject.projectId}
				/>
			)}

			{isUsersModalVisible && (
				<UsersModal
					visible={isUsersModalVisible}
					onCancel={() => setIsUsersModalVisible(false)}
					project={selectedProject}
				/>
			)}
			{isDeleteModalVisible && (
				<DeleteModal
					deleteModalVisibility={isDeleteModalVisible}
					project={selectedProject}
					setIsReload={setIsReloading}
					setProjectsData={setProjectsData}
					onCancel={() => setIsDeleteModalVisible(false)}
				/>
			)}
		</section>
	);
};

export default Projects;
