import { PATHS_PARAMS, PATHS } from "constants/PATHS";
import { getResource, postResource } from "services";

export function getMonthlyAndAnnualTransaction(
  { serviceTypeId, fromDate, toDate, mode },
  onSuccess,
  onFail
) {
  let data = {
    serviceTypeId,
  };
  if (mode === "month") {
    data.fromMonth = fromDate;
    data.toMonth = toDate;
  } else {
    data.fromYear = fromDate;
    data.toYear = toDate;
  }

  const path = PATHS(PATHS_PARAMS.getMonthlyAndAnnualTransaction);
  postResource(path, data, onSuccess, onFail);
}

export function getAllServiceTypes(onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.getServiceTypes);
  getResource(path, onSuccess, onFail);
}
