import { Tooltip } from "antd";

const CustomTooltip = ({ text, count, placement }) => {
	function truncateString() {
		if (text?.length > count) {
			return text.slice(0, count) + "... ";
		} else {
			return text;
		}
	}
	const isVisible = {
		...(text.length < count && { visible: false }),
	};

	return (
		<Tooltip title={text} placement={placement} {...isVisible}>
			{truncateString()}
		</Tooltip>
	);
};

export default CustomTooltip;
