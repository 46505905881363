import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import pagenotFoundImg from "assets/Images/page_not_found.svg";
import ROUTE, { homeRoute } from "constants/ROUTES";

function NotFound() {
  const { authorization } = useSelector((state) => state.auth);
  let backRoute = authorization?.token ? homeRoute : ROUTE.HOME;
  return (
    <main className="text-center not-found">
      <div className="container">
        <div className="not-found__container d-flex justify-content-center align-items-center flex-column">
          <figure className="not-found__figure mb-4">
            <img src={pagenotFoundImg} alt="Page not found" />
          </figure>
          <div className="text-container mb-5">
            <p>
              Ooops! <br /> Page Not Found
            </p>
          </div>
          <Link className="not-found__back text-uppercase" to={backRoute}>
            back to home
          </Link>
        </div>
      </div>
    </main>
  );
}

export default NotFound;
