import { getResource, postResource } from "services";
import { PATHS, PATHS_PARAMS } from "constants/PATHS";

const statuses = {
	bidder: [2, 3, 4, 5, 7, 8, 9],
	hire: [5],
};

export function postNumberOfBiddersForEveryService(
	hirringOrBidding,
	onSuccess,
	onFail
) {
	let data = {
		statuses: statuses[hirringOrBidding],
	};
	const path = PATHS(PATHS_PARAMS.numberOfBiddersForEveryService);
	postResource(path, data, onSuccess, onFail);
}

export function postNumberOfBiddersForEveryArea(
	hirringOrBidding,
	onSuccess,
	onFail
) {
	const data = {
		areaList: [
			{
				from: 50,
				to: 200,
				status: 1,
			},
			{
				from: 200,
				to: 400,
				status: 1,
			},
			{
				from: 400,
				status: 3,
			},
		],
		statuses: statuses[hirringOrBidding],
	};

	const path = PATHS(PATHS_PARAMS.numberOfBiddersForEveryArea);
	postResource(path, data, onSuccess, onFail);
}

export function postNumberOfBiddersForEverDayInLastMonth(onSuccess, onFail) {
	const data = {
		days: [5, 10, 15, 20, 25],
	};
	const path = PATHS(PATHS_PARAMS.numberOfBiddersForEverDayInLastMonth);
	postResource(path, data, onSuccess, onFail);
}

export function postNumberOfHiringsForEveryDayInLastMonth(onSuccess, onFail) {
	const data = {
		days: [5, 10, 15, 20, 25],
	};
	const path = PATHS(PATHS_PARAMS.numberOfHiringInLastMonth);
	postResource(path, data, onSuccess, onFail);
}

export function getNumberOfHiringDividedByBidders(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.numberOfHiringDividedByBidders);
	getResource(path, onSuccess, onFail);
}

export function getNumberOfHiringDividedByShortlisting(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.numberOfHiringDividedByShortlisting);
	getResource(path, onSuccess, onFail);
}

export function postNumberOfFeedbacksDividedByBidders(
	hirringOrBidding,
	onSuccess,
	onFail
) {
	let data = {
		statuses: statuses[hirringOrBidding],
	};
	const path = PATHS(PATHS_PARAMS.numberOfFeedbacksDividedByBidders);
	postResource(path, data, onSuccess, onFail);
}

export function getNumberOfShortlistingDividedByBidders(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.numberOfShortlistingDividedByBidders);
	getResource(path, onSuccess, onFail);
}

export function postNumberOfProAccountsInEachDayInLastMonth(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.numberOfProAccountsInEachDayInLastMonth);
	const data = {
		days: [5, 10, 15, 20, 25],
	};
	postResource(path, data, onSuccess, onFail);
}
