import { useEffect, useState } from "react";
import { Dropdown, Switch, Radio, Input, Menu, Button, Checkbox } from "antd";
import {
	getAllQuestions,
	getServiceQuestionByQuestionId,
	addServiceQuestion,
	editServiceQuestion,
	addServiceAnswer,
	editSerivceAnswer,
	deleteServiceAnswer,
} from "../network";
import copyIcon from "assets/Icons/copy.svg";
import deleteIcon from "assets/Icons/delete-main-color.svg";
import DeleteQuestionModal from "./DeleteQuestion";
import { toastError, toastSuccess } from "helpers/toasters";
import closeIcon from "assets/Icons/CloseIcon.svg";
import addFieldIcon from "assets/Icons/add-field.svg";

const MultipleChoises = ({ serviceId }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isActive, setIsActive] = useState(false);
	// matchedDegree => [isPro, isHomeOwner] => [Boolean, Boolean]
	const [matchedDegree, setMatchedDegree] = useState([true, false]);
	const [matchedDegreeRadios, setMatchedDegreeRadios] = useState(1);
	const [question, setQuestion] = useState(null);
	const [arQuestion, setArQuestion] = useState(null);
	const [selectedInbox, setSelectedIndex] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const [isRequired, setIsRequired] = useState(false);
	const [isRequiredDisabled, setIsRequiredDisabled] = useState(false);
	const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
	const [allQuestions, setAllQuestions] = useState([]);
	const [selectedQuestion, setSelectedQuestion] = useState(null);
	const [hasOther, setHasOther] = useState(false);
	const [initailAnswers, setInitailAnswers] = useState([
		{
			serviceAnswers: {
				ar: "",
				en: "",
			},
			serviceID: serviceId,
		},
		{
			serviceAnswers: {
				ar: "",
				en: "",
			},
			serviceID: serviceId,
		},
	]);

	useEffect(() => {
		getAllServiceQuestions();
		// eslint-disable-next-line
	}, [serviceId]);

	useEffect(() => {
		if (selectedQuestion) {
			const {
				isActive,
				isHomeOwner,
				isPro,
				isRequired: selectedQuestionRequired,
				serviceQuestion,
				index,
				hasOtherAnswer,
			} = selectedQuestion;
			setIsActive(isActive);
			setMatchedDegree([isPro, isHomeOwner]);
			setIsRequired(selectedQuestionRequired);
			setQuestion(serviceQuestion.en);
			setArQuestion(serviceQuestion.ar);
			setSelectedIndex(index);
			setHasOther(hasOtherAnswer);
		} else {
			resetAllQuestionAndAnswers();
		}
		// eslint-disable-next-line
	}, [selectedQuestion]);

	useEffect(() => {
		if (selectedQuestion) {
			const { isPro, isHomeOwner } = selectedQuestion;
			if (isPro && !isHomeOwner) setMatchedDegreeRadios(1);
			if (!isPro && isHomeOwner) setMatchedDegreeRadios(2);
			if (isPro && isHomeOwner) setMatchedDegreeRadios(3);
		}
	}, [selectedQuestion]);

	useEffect(() => {
		if (matchedDegreeRadios === 3) {
			setMatchedDegree([true, true]);
			setIsRequired(true);
			setIsRequiredDisabled(true);
		} else if (matchedDegreeRadios === 2) {
			setMatchedDegree([false, true]);
			setIsRequiredDisabled(false);
		} else {
			setMatchedDegree([true, false]);
			setIsRequiredDisabled(false);
		}
	}, [matchedDegreeRadios]);

	useEffect(() => {
		if (
			question &&
			arQuestion &&
			initailAnswers.filter(
				(answer) =>
					answer?.serviceAnswers?.en?.length &&
					answer?.serviceAnswers?.ar?.length
			).length >= 2
		) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [question, arQuestion, initailAnswers]);

	function getAllServiceQuestions() {
		setIsLoading(true);
		getAllQuestions(
			{ serviceID: serviceId },
			(success) => {
				setIsLoading(false);
				setAllQuestions(success.data);
				resetAllQuestionAndAnswers();
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	function resetAllQuestionAndAnswers() {
		setSelectedQuestion(null);
		setMatchedDegreeRadios(1);
		setQuestion(null);
		setArQuestion(null);
		setSelectedIndex(null);
		setIsActive(false);
		setIsRequired(false);
		setIsMenuOpen(false);
		setHasOther(false);
		setInitailAnswers([
			{
				serviceAnswers: {
					en: "",
					ar: "",
				},
				serviceID: serviceId,
			},
			{
				serviceAnswers: {
					en: "",
					ar: "",
				},
				serviceID: serviceId,
			},
		]);
	}

	function changeMatchedVal(event) {
		const {
			target: { value },
		} = event;
		setMatchedDegreeRadios(value);
	}

	function copyQuestion() {
		if (selectedQuestion) {
			let payload = selectedQuestion;
			payload.index = allQuestions.length + 1;
			addQuestion(payload);
			setIsMenuOpen(false);
		}
	}

	function onSelectMenuItem(event, questionId) {
		event.domEvent.stopPropagation();
		if (selectedQuestion) {
			if (questionId === selectedQuestion?._id) {
				// clear selection from this question
				setSelectedQuestion(null);
			} else getQuestionById(questionId);
		} else getQuestionById(questionId);
	}

	function openCloseDropdown() {
		if (allQuestions.length) setIsMenuOpen((prev) => !prev);
	}

	function getQuestionById(questionId) {
		setIsLoading(true);
		getServiceQuestionByQuestionId(
			{ questionId },
			(success) => {
				setIsLoading(false);
				setSelectedQuestion(success.data);
				setInitailAnswers(success.data.answer);
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	function addAnswer(questionId) {
		let answers = initailAnswers.map((answer) => {
			answer.serviceQuestionsID = questionId;
			answer["serviceAnswers.en"] = answer.serviceAnswers.en;
			answer["serviceAnswers.ar"] = answer.serviceAnswers.ar;
			delete answer._id;
			return answer;
		});
		let payload = answers;
		addServiceAnswer(
			payload,
			(success) => {
				const {
					data: { messsage },
				} = success;
				toastSuccess(messsage);
				getAllServiceQuestions();
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	}

	function editAnswer(questionId) {
		let answers = initailAnswers.map((answer) => {
			answer.serviceQuestionsID = questionId;
			return answer;
		});
		editSerivceAnswer(
			{ answerIds: answers, questionId },
			(success) => {
				setIsLoading(false);
				toastSuccess(success.data.message);
				getAllServiceQuestions();
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	/* -------------------------------------------------------------------------- */
	/*                              Questions Methods                             */
	/* -------------------------------------------------------------------------- */
	function addNewQuestion() {
		setIsLoading(true);
		let payload = {
			serviceID: serviceId,
			serviceQuestion: {
				en: question,
				ar: arQuestion,
			},
			hasOtherAnswer: hasOther,
			isPro: matchedDegree[0],
			isHomeOwner: matchedDegree[1],
			index: selectedInbox,
			isMatch: matchedDegree[0] && matchedDegree[1],
			isActive,
			isRequired,
		};
		if (!selectedInbox) payload.index = allQuestions.length + 1;
		if (selectedQuestion) {
			payload.questionId = selectedQuestion._id;
			edtiQuestion(payload);
		} else {
			addQuestion(payload);
		}
	}

	function addQuestion(payload) {
		addServiceQuestion(
			payload,
			(success) => {
				const { _id } = success.data;
				addAnswer(_id);
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	function edtiQuestion(payload) {
		setIsLoading(true);
		editServiceQuestion(
			payload,
			(success) => {
				editAnswer(selectedQuestion._id);
			},
			(fail) => {
				if (fail?.data.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	/* -------------------------------------------------------------------------- */
	/*                               Answers Methods                              */
	/* -------------------------------------------------------------------------- */
	function addAnswerField(questionId) {
		setInitailAnswers((prev) => [
			...prev,
			{
				serviceAnswers: {
					en: "",
					ar: "",
				},
				serviceID: serviceId,
			},
		]);
	}

	function deleteAnswerField(_idx) {
		if (initailAnswers[_idx]?._id) {
			setIsLoading(true);
			deleteServiceAnswer(
				{ answerId: initailAnswers[_idx]._id },
				(success) => {
					setIsLoading(false);
					toastSuccess(success.message);
					// after delete from api delete from
					setInitailAnswers((prev) => {
						let filteredData = prev.filter((answer, index) => {
							if (index === _idx) return null;
							else return answer;
						});
						return [...filteredData];
					});
				},
				(fail) => {
					setIsLoading(false);
					if (fail?.data?.error) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		} else {
			setInitailAnswers((prev) => {
				let filteredData = prev.filter((answer, index) => {
					if (index === _idx) return null;
					else return answer;
				});
				return [...filteredData];
			});
		}
	}

	function onChangeAnswer(e, _idx, en) {
		setInitailAnswers((prev) => {
			let answers = prev;
			if (en) answers[_idx].serviceAnswers.en = e.target.value;
			else answers[_idx].serviceAnswers.ar = e.target.value;
			// answers.map((answer, index) => {
			//   if (index === _idx) answers.serviceAnswers = e.target.value;
			//   return answer;
			// });
			return [...answers];
		});
	}

	const menu = () => (
		<Menu
			className="menu"
			style={{
				width: "95px",
				maxHeight: 250,
				overflow: "auto",
			}}
		>
			<Radio.Group
				style={{
					width: "100%",
				}}
				value={selectedInbox}
				onChange={(e) => {
					e.stopPropagation();
					setSelectedIndex(e.target.value);
				}}
			>
				{allQuestions.length
					? allQuestions.map((question, _idx) => (
							<Menu.Item
								key={_idx}
								style={{
									display: "flex",
								}}
								onClick={(event) => {
									if (!question?.isTimeFrame) {
										onSelectMenuItem(event, question?._id);
									}
								}}
							>
								<div className="d-flex">
									<Radio
										value={question.index}
										onClick={(e) => {
											e.stopPropagation();
										}}
									/>
									<div
										style={{
											textDecoration:
												selectedQuestion?._id === question?._id
													? "underline"
													: "none",
											fontSize: "1rem",
											// opacity: question.isLocation ? "0.5" : "1",
											color: question?.isTimeFrame ? "gray" : "inherit",
											cursor: "",
											// color:"red"
										}}
										role={"button"}
									>
										{_idx + 1}
									</div>
								</div>
							</Menu.Item>
					  ))
					: null}
			</Radio.Group>
		</Menu>
	);

	return (
		<>
			<div className="multiple">
				<div className="d-flex align-items-center">
					<h5 className="service__h5">Multiple Choice</h5>
				</div>
				<div className="service__container box-shadow">
					<div className="service__drop-swtich">
						<Dropdown
							overlayStyle={{
								width: "250px",
							}}
							overlay={menu}
							className="service__dropdown"
							overlayClassName="service__dropdown__overlay"
							visible={isMenuOpen}
						>
							<div
								className="inbox striched-link"
								onClick={openCloseDropdown}
								role={"button"}
							>
								<div>Index</div>
							</div>
						</Dropdown>
						<div className="service__switch-container">
							<div>Active</div>
							<Switch
								checked={isActive}
								onChange={() => setIsActive((prev) => !prev)}
								className="service__switch"
							/>
						</div>
					</div>
					<Radio.Group
						className="multiple__radios"
						buttonStyle={{
							border: "1px solid red",
						}}
						onChange={(value) => changeMatchedVal(value)}
						value={matchedDegreeRadios}
					>
						<Radio value={1}>Service Type Pref.</Radio>
						<Radio value={2}>Brief</Radio>
						<Radio value={3}>Match</Radio>
					</Radio.Group>
					<Input
						className="service__input"
						placeholder="Type Question..."
						value={question}
						onChange={(e) => setQuestion(e.target.value)}
					/>
					<Input
						className="service__input"
						placeholder="أكتب سؤال..."
						dir="rtl"
						value={arQuestion}
						onChange={(e) => setArQuestion(e.target.value)}
					/>
					<div className="service__answers">
						{initailAnswers.map((answer, _idx) => (
							<div className="answer" key={_idx}>
								<Input
									placeholder="Type Option..."
									className="answer__input"
									value={answer.serviceAnswers?.en}
									onChange={(e) => onChangeAnswer(e, _idx, true)}
								/>
								<Input
									placeholder="أكتب أختيار..."
									className="answer__input"
									dir="rtl"
									value={answer.serviceAnswers?.ar}
									onChange={(e) => onChangeAnswer(e, _idx)}
								/>
								{_idx > 1 ? (
									<img
										role={"button"}
										src={closeIcon}
										onClick={() => deleteAnswerField(_idx)}
										alt="delet"
									/>
								) : null}
							</div>
						))}

						<div className="answer__add" role="button" onClick={addAnswerField}>
							<img src={addFieldIcon} className="answer__img" alt="delet" />
							Add Option
						</div>

						<Checkbox
							value={hasOther}
							checked={hasOther}
							onChange={() => setHasOther((prev) => !prev)}
							className={"service__other"}
						>
							<div>Other Answer</div>
						</Checkbox>
					</div>

					<div className="service__copy-delete-required">
						<div className="d-flex align-items-center">
							<img
								src={copyIcon}
								onClick={copyQuestion}
								role={"button"}
								alt="copy"
								style={{
									cursor: selectedQuestion ? "pointer" : "not-allowed",
									opacity: selectedQuestion ? "1" : "0.5",
								}}
							/>
							<img
								src={deleteIcon}
								role={"button"}
								onClick={() => {
									setIsMenuOpen(false);
									setIsDeleteModalOpened(true);
								}}
								alt="delete"
								style={{
									cursor: selectedQuestion ? "pointer" : "not-allowed",
									opacity: selectedQuestion ? "1" : "0.5",
								}}
							/>
						</div>

						<div className="service__switch-container">
							<div>Required</div>
							<Switch
								checked={isRequired}
								onChange={() => setIsRequired((prev) => !prev)}
								disabled={isRequiredDisabled}
							/>
						</div>
					</div>

					<div className="service__button">
						<Button
							style={{
								width: "133px",
								height: "42px",
							}}
							type="primary"
							loading={isLoading}
							disabled={isDisabled}
							onClick={addNewQuestion}
						>
							{selectedQuestion ? "Edit" : "Submit"}
						</Button>
					</div>
				</div>
			</div>
			{isDeleteModalOpened && (
				<DeleteQuestionModal
					deleteModalVisibility={isDeleteModalOpened}
					selectedQuestion={selectedQuestion}
					getAllQuestions={getAllServiceQuestions}
					onCancel={() => setIsDeleteModalOpened(false)}
					isService
				/>
			)}
		</>
	);
};

export default MultipleChoises;
