import React, { useState, useEffect } from "react";
import { Button, Form, Input, Radio } from "antd";
import {
	addUtilContact,
	getUtlsAndMore,
	uploadUtlContactImage,
} from "modules/Pages/PagesModal/network";
import Dropdown from "react-bootstrap/Dropdown";
import isEmail from "validator/lib/isEmail";
import validator from "validator";
import UploadIcon from "assets/Icons/upload.svg";
import contact from "assets/Images/contact.png";
import { baseUrl } from "services";
import { toastSuccess } from "helpers/toasters";

function AddUtilityDetail({ getMode }) {
	const [utilities, updateUtilities] = useState([]);
	const [name, updateName] = useState("");
	const [utility, updateUtility] = useState(null);
	const [image, updateImage] = useState("");
	const [contacts, updateContacts] = useState("");
	const [email, updateEmail] = useState("");
	const [website, updateWebsite] = useState("");
	const [socialLinks, updateSocialLinks] = useState([""]);
	const [loading, updateLoading] = useState([
		{ uploadImg: false, submit: false },
	]);
	const [anotherContact, updateAnotherContact] = useState("");
	const [isActive, setIsActive] = useState(true);
	const [mediaFormatAlert, updateMediaFormatAlert] = useState(null);

	const onActivateChange = (e) => {
		setIsActive(e.target.value);
	};
	const getAllUtils = () => {
		getUtlsAndMore(
			(success) => {
				updateUtilities(success.data);
			},
			(fail) => {}
		);
	};
	const handleUploadImage = (e) => {
		const isImage = e.target.files[0].type.includes("image");
		if (!isImage) {
			updateMediaFormatAlert("* Please, upload an image");
		} else {
			updateMediaFormatAlert(null);
			let loadingVar = { ...loading };
			loadingVar.uploadImage = true;
			updateLoading(loadingVar);

			let imageDataFile = new FormData();
			imageDataFile.append(
				"image",
				e.target.files[0],
				`-${e.target.files[0].type.split("/")[0]}-${e.target.files[0].name}`
			);

			uploadUtlContactImage(
				imageDataFile,
				(success) => {
					let loadingVar = { ...loading };
					loadingVar.uploadImage = false;
					updateLoading(loadingVar);
					if (success.isSuccess) {
						let fileData = Object.assign(e.target.files[0]);
						fileData.path = success.data.path;
						updateImage(fileData);
					}
				},
				(fail) => {
					let loadingVar = { ...loading };
					loadingVar.uploadImage = false;
					updateLoading(loadingVar);
				}
			);
		}
	};
	useEffect(() => {
		getAllUtils();
	}, []);
	const onFinish = () => {
		let loadingVar = { ...loading };
		loadingVar.save = true;
		updateLoading(loadingVar);

		let data = {
			utilId: utility,
			"name.en": name,
			"name.ar": name,
			image: image.path,
			email,
			contacts,
			website,
			socialLinks,
			isActive,
			anotherContact,
		};
		addUtilContact(
			data,
			(success) => {
				let loadingVar = { ...loading };
				loadingVar.save = false;
				updateLoading(loadingVar);
				if (success.isSuccess) {
					toastSuccess(success.message);
					getMode("view");
				}
			},
			(fail) => {
				let loadingVar = { ...loading };
				loadingVar.save = false;
				updateLoading(loadingVar);
			}
		);
	};
	return (
		<div className="add-util">
			<Form onFinish={onFinish} name="basic">
				<div className="d-flex justify-content-between">
					<div className="d-flex flex-1 flex-column justify-content-end">
						<Form.Item
							name="name"
							colon={false}
							valuePropName={name}
							required
							requiredMark={false}
							rules={[
								{
									required: true,
									validator: (_, value) => {
										if (name) {
											return Promise.resolve();
										} else {
											return Promise.reject(
												new Error("Please, Enter The Name")
											);
										}
									},
								},
							]}
						>
							<Input
								className="bap-input"
								type="text"
								value={name}
								onChange={(e) => {
									updateName(e.target.value);
								}}
								placeholder="Name"
							/>
						</Form.Item>
						<Form.Item
							className="d-flex"
							name="image"
							colon={false}
							valuePropName={image}
							required
							key={image.path}
							requiredMark={false}
							rules={[
								{
									required: true,
									validator: (_, value) => {
										if (image) {
											return Promise.resolve();
										} else {
											return Promise.reject(
												new Error("Please, Upload an image")
											);
										}
									},
								},
							]}
						>
							<input
								type="file"
								id="image"
								className="d-none"
								onChange={handleUploadImage}
							/>
							{image.path && (
								<img
									className="avatar me-2 d-inline-block"
									src={baseUrl + "/" + image.path}
									alt="avatar"
								/>
							)}
							<Button
								loading={loading.uploadImage}
								className="bap-outline-button cursor-pointer d-inline-block m-0 p-0"
							>
								<label htmlFor="image" className="cursor-pointer">
									<span>Upload Image</span>
									<img src={UploadIcon} alt="upload" />
								</label>
							</Button>
							{mediaFormatAlert && (
								<div className="mx-2 text-danger">{mediaFormatAlert}</div>
							)}
						</Form.Item>
						<Form.Item
							name="contacts"
							colon={false}
							valuePropName={contacts}
							required
							requiredMark={false}
							rules={[
								{
									required: true,
									validator: (_, value) => {
										if (
											!contacts ||
											contacts?.length < 11 ||
											contacts?.length > 11 ||
											!contacts?.startsWith("01")
										) {
											return Promise.reject(
												new Error("Please, Enter a valid Mobile Number")
											);
										} else {
											return Promise.resolve();
										}
									},
								},
							]}
						>
							<Input
								className="bap-input"
								type="number"
								value={contacts}
								onChange={(e) => {
									updateContacts(e.target.value);
								}}
								placeholder="Mobile Number"
							/>
						</Form.Item>
						<Form.Item
							name="contacts"
							colon={false}
							valuePropName={contacts}
							required
							requiredMark={false}
							rules={[
								{
									required: false,
									validator: (_, value) => {
										if (
											(anotherContact?.length < 11 ||
												anotherContact?.length > 11 ||
												!anotherContact?.startsWith("01")) &&
											anotherContact.length !== 0
										) {
											return Promise.reject(
												new Error("Please, Enter a valid Mobile Number")
											);
										} else {
											return Promise.resolve();
										}
									},
								},
							]}
						>
							<Input
								className="bap-input"
								type="number"
								value={anotherContact}
								onChange={(e) => {
									updateAnotherContact(e.target.value);
								}}
								placeholder="Another Mobile Number"
							/>
						</Form.Item>
						<Form.Item
							name="email"
							colon={false}
							valuePropName={email}
							required
							requiredMark={false}
							rules={[
								{
									required: false,
									validator: (_, value) => {
										if (email && !isEmail(email)) {
											return Promise.reject(
												new Error("Please, Enter a valid Email")
											);
										} else {
											return Promise.resolve();
										}
									},
								},
							]}
						>
							<Input
								className="bap-input"
								type="email"
								value={email}
								onChange={(e) => {
									updateEmail(e.target.value);
								}}
								placeholder="Email"
							/>
						</Form.Item>
						<Form.Item
							name="website"
							colon={false}
							valuePropName={website}
							required
							requiredMark={false}
							rules={[
								{
									required: false,
									validator: (_, value) => {
										if (website && !validator.isURL(website)) {
											return Promise.reject(
												new Error("Please, Enter a valid website")
											);
										} else {
											return Promise.resolve();
										}
									},
								},
							]}
						>
							<Input
								className="bap-input"
								type="text"
								value={website}
								onChange={(e) => {
									updateWebsite(e.target.value);
								}}
								placeholder="Website"
							/>
						</Form.Item>
						{socialLinks?.map((socialLink, index) => {
							return (
								<Form.Item
									name="socialLink"
									colon={false}
									valuePropName={socialLink}
									required
									requiredMark={false}
									rules={[
										{
											required: false,
											validator: (_, value) => {
												if (socialLink && !validator.isURL(socialLink)) {
													return Promise.reject(
														new Error("Please, Enter a valid social link")
													);
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<Input
										className="bap-input"
										type="text"
										value={socialLink}
										onChange={(e) => {
											let socialLinksList = [...socialLinks];
											socialLinksList[index] = e.target.value;
											updateSocialLinks(socialLinksList);
										}}
										placeholder="Socials"
									/>
								</Form.Item>
							);
						})}
						<div
							className="main-color text-underline cursor-pointer"
							onClick={() => {
								let socialLinksList = [...socialLinks];
								socialLinksList.push("");
								updateSocialLinks(socialLinksList);
							}}
						>
							Add "Socials"
						</div>
						<div className="d-flex align-items-center my-2">
							<img src={contact} alt="contact" className="mx-2" />
							<Radio.Group onChange={onActivateChange} value={isActive}>
								<Radio value={true}>Activate</Radio>
							</Radio.Group>
						</div>
					</div>
					<div className="d-flex flex-1 flex-column align-items-end">
						<Form.Item
							name="utility"
							colon={false}
							valuePropName={utility}
							required
							key={utility}
							requiredMark={false}
							rules={[
								{
									required: true,
									validator: (_, value) => {
										if (utility) {
											return Promise.resolve();
										} else {
											return Promise.reject(
												new Error("Please, Select a utility")
											);
										}
									},
								},
							]}
						>
							<Dropdown>
								<Dropdown.Toggle
									className="px-4"
									variant="success"
									id="dropdown-basic"
								>
									Category
								</Dropdown.Toggle>
								<Dropdown.Menu>
									{utilities.map((utility, index) => {
										return (
											<Dropdown.Item key={index}>
												<div
													onClick={() => {
														updateUtility(utility._id);
													}}
												>
													{utility.title.en}
												</div>
											</Dropdown.Item>
										);
									})}
								</Dropdown.Menu>
							</Dropdown>
						</Form.Item>
					</div>
				</div>
				<div className="text-center">
					<Button
						htmlType="submit"
						type="primary"
						className="bap-primary-button bap-button"
						loading={loading.save}
					>
						Save
					</Button>
				</div>
			</Form>
		</div>
	);
}

export default AddUtilityDetail;
