import { useState, useEffect } from "react";
import { Button, Spin } from "antd";
import { getAllFeedbackUsers } from "../network";
import FeedbackModal from "./components/FeedbackModal";
import { toastError } from "helpers/toasters";
import { ACCOUNT_TYPES } from "constants/UserAccounts";
import AvarageFeedback from "./components/AvarageFeedbacks";

const Feedback = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [feedbackClients, setFeedbackClients] = useState([]);
  const [feedbackPros, setFeedbackPros] = useState([]);

  const [clientLoading, setClientLoading] = useState(false);
  const [proLoading, setProLoading] = useState(false);

  const [isOverallRadingModalOpen, setIsOverallRadingModalOpen] =
    useState(false);
  const [isUserRatingModalOpen, setIsUserRatingModalOpen] = useState(false);
  const [selectedAccountTypeId, setSelectedAccountTypeId] = useState(null);

  useEffect(() => {
    const proId = ACCOUNT_TYPES.pro._id;
    const clientId = ACCOUNT_TYPES.propertyOwner._id;
    setProLoading(true);
    setClientLoading(true);
    getAllFeedbackUsers(
      { accountTypeId: proId },
      (success) => {
        setProLoading(false);
        setFeedbackPros(success.data);
      },
      (fail) => {
        setProLoading(false);
        if (fail?.data?.error) {
          toastError(fail.data.error);
        } else {
          toastError();
        }
      }
    );

    getAllFeedbackUsers(
      { accountTypeId: clientId },
      (success) => {
        setClientLoading(false);
        setFeedbackClients(success.data);
      },
      (fail) => {
        setClientLoading(false);
        if (fail?.data?.error) {
          toastError(fail.data.error);
        } else {
          toastError();
        }
      }
    );
  }, []);

  function selectUser(user, role) {
    setSelectedUser(user);
    setSelectedRole(role);
    setIsUserRatingModalOpen(true);
  }

  function selectOverallRating(accountType) {
    setSelectedRole(accountType.accountType);
    setSelectedAccountTypeId(accountType._id);
    setIsOverallRadingModalOpen(true);
  }

  return (
    <>
      <section className="main-section servery">
        <header className="main-header">
          <h2>Feedback</h2>
        </header>
        <div className=" servery__container box-shadow">
          {/* servcie */}
          <div className="servery__services box-shadow">
            <div className="servery__header">
              <h5>Pro survey</h5>
              <Button
                type="primary"
                onClick={() => selectOverallRating(ACCOUNT_TYPES.pro)}
              >
                Overall Rating
              </Button>
            </div>
            <div className="servery__users">
              {proLoading ? (
                <Spin className="servery__spin" />
              ) : (
                <>
                  {feedbackPros.map((user, _idx) => (
                    <div className="user">
                      <span
                        role={"button"}
                        onClick={() => {
                          selectUser(user, "Pro");
                        }}
                      >
                        {user.userName} -{" "}
                        <small
                          style={{
                            fontSize: ".9rem",
                            color: "#707070",
                          }}
                        >
                          project: {user.projectName}
                        </small>
                      </span>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>

          <div className="border"></div>

          {/* servcie */}
          <div className="servery__services box-shadow">
            <div className="servery__header">
              <h5>Property Owner survey</h5>
              <Button
                type="primary"
                onClick={() => selectOverallRating(ACCOUNT_TYPES.propertyOwner)}
              >
                Overall Rating
              </Button>
            </div>
            <div className="servery__users">
              {clientLoading ? (
                <Spin className="servery__spin" />
              ) : (
                <>
                  {feedbackClients.map((user, _idx) => (
                    <div className="user">
                      <span
                        role="button"
                        onClick={() => {
                          selectUser(user, "Property Owner");
                        }}
                      >
                        {user.userName} -{" "}
                        <small
                          style={{
                            fontSize: ".9rem",
                            color: "#707070",
                          }}
                        >
                          project: {user.projectName}
                        </small>
                      </span>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        {isUserRatingModalOpen && (
          <FeedbackModal
            visible={isUserRatingModalOpen}
            onCancel={() => {
              setSelectedUser(null);
              setIsUserRatingModalOpen(false);
            }}
            user={selectedUser}
            role={selectedRole}
          />
        )}

        {isOverallRadingModalOpen && (
          <AvarageFeedback
            visible={isOverallRadingModalOpen}
            onCancel={() => setIsOverallRadingModalOpen(false)}
            role={selectedRole}
            accountTypeId={selectedAccountTypeId}
          />
        )}
      </section>
    </>
  );
};

export default Feedback;
