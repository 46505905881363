import { useEffect, useState } from "react";
import {
	Dropdown,
	Switch,
	Radio,
	Input,
	InputNumber,
	Menu,
	Button,
} from "antd";
import {
	getParagraphQuestions,
	addParagraphQuestions,
	editParagraphQuestion,
} from "../network";
import copyIcon from "assets/Icons/copy.svg";
import deleteIcon from "assets/Icons/delete-main-color.svg";
import DeleteQuestionModal from "./DeleteQuestion";
import { toastError, toastSuccess } from "helpers/toasters";

const ParagraphQuestion = ({ serviceId }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [question, setQuestion] = useState(null);
	const [arQuestion, setArQuestion] = useState(null);
	const [selectedInbox, setSelectedIndex] = useState(null);
	const [maxNumberOfCharacter, setMaxNumberOfCharacter] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const [isRequired, setIsRequired] = useState(false);
	const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
	const [allQuestions, setAllQuestions] = useState([]);
	const [selectedQuestion, setSelectedQuestion] = useState(null);

	useEffect(() => {
		getAllQuestions();
		//  eslint-disable-next-line
	}, [serviceId]);

	useEffect(() => {
		if (selectedQuestion) {
			const { isActive, isRequired, paragraphQuestion, textMaxSize, index } =
				selectedQuestion;
			setIsActive(isActive);
			setIsRequired(isRequired);
			setQuestion(paragraphQuestion.en);
			setArQuestion(paragraphQuestion.ar);
			// setArQuestion
			setMaxNumberOfCharacter(textMaxSize);
			setSelectedIndex(index);
		} else {
			resetAllQuestionAndAnswers(false);
		}
	}, [selectedQuestion]);

	useEffect(() => {
		if (question && arQuestion && maxNumberOfCharacter) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [question, arQuestion, maxNumberOfCharacter]);

	function resetAllQuestionAndAnswers() {
		setSelectedQuestion(null);
		setMaxNumberOfCharacter(0);
		setQuestion(null);
		setArQuestion(null);
		setSelectedIndex(null);
		setIsActive(false);
		setIsRequired(false);
		setIsMenuOpen(false);
	}

	function copyQuestion() {
		if (selectedQuestion) {
			let payload = selectedQuestion;
			payload.index = allQuestions.length;
			payload.paragraphQuestions = {
				en: question,
				ar: arQuestion,
			};
			addQuestion(payload);
			setIsMenuOpen(false);
		}
	}

	function onSelectMenuItem(questionId, item, event) {
		event.domEvent.stopPropagation();
		if (selectedQuestion) {
			if (questionId === selectedQuestion?._id) {
				setSelectedQuestion(null);
			} else setSelectedQuestion(item);
		} else setSelectedQuestion(item);
	}

	function openCloseDropdown() {
		if (allQuestions.length) setIsMenuOpen((prev) => !prev);
	}

	function getAllQuestions() {
		setIsLoading(true);
		getParagraphQuestions(
			{ serviceId },
			(success) => {
				setIsLoading(false);
				setAllQuestions(success.data);
				resetAllQuestionAndAnswers();
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	function addQuestion(payload) {
		addParagraphQuestions(
			payload,
			(success) => {
				getAllQuestions();
				toastSuccess(success.message);
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	function edtiQuestion(payload) {
		editParagraphQuestion(
			payload,
			(success) => {
				toastSuccess(success.message);
				getAllQuestions();
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	function addNewQuestion() {
		setIsLoading(true);
		let payload = {
			paragraphQuestions: {
				ar: arQuestion,
				en: question,
			},
			serviceId,
			index: selectedInbox,
			isPro: false,
			isHomeOwner: true,
			textMaxSize: maxNumberOfCharacter,
			isRequired,
			isActive,
		};

		if (selectedQuestion) {
			payload.questionId = selectedQuestion._id;

			edtiQuestion(payload);
		} else {
			// if (!selectedInbox) {
			// 	payload.index = selectedQuestion.index;
			// }
			addQuestion(payload);
		}
	}

	const menu = () => (
		<Menu
			className="menu"
			style={{
				width: "95px",
				maxHeight: 250,
				overflow: "auto",
			}}
		>
			<Radio.Group
				style={{
					width: "100%",
				}}
				value={selectedInbox}
				onChange={(e) => {
					e.stopPropagation();
					setSelectedIndex(e.target.value);
				}}
			>
				{allQuestions.length
					? allQuestions.map((item, _idx) => (
							<Menu.Item
								key={_idx}
								style={{
									display: "flex",
								}}
								onClick={(event) => onSelectMenuItem(item?._id, item, event)}
							>
								<div className="d-flex">
									<Radio
										value={_idx}
										onClick={(e) => {
											e.stopPropagation();
										}}
									/>
									<div
										style={{
											textDecoration:
												selectedQuestion?._id === item?._id
													? "underline"
													: "none",
											fontSize: "1rem",
										}}
										role={"button"}
									>
										{_idx + 1}
									</div>
								</div>
							</Menu.Item>
					  ))
					: null}
			</Radio.Group>
		</Menu>
	);

	return (
		<>
			<div className="multiple">
				<div className="d-flex align-items-center">
					<h5 className="service__h5">Paragraph</h5>
					<img src="" alt="" />
				</div>
				<div className="service__container box-shadow">
					<div className="service__drop-swtich">
						<Dropdown
							overlayStyle={{
								width: "250px",
							}}
							overlay={menu}
							className="service__dropdown"
							overlayClassName="service__dropdown__overlay"
							visible={isMenuOpen}
						>
							<div
								className="inbox striched-link"
								onClick={openCloseDropdown}
								role={"button"}
							>
								<div>Index</div>
							</div>
						</Dropdown>
						<div className="service__switch-container">
							<div>Active</div>
							<Switch
								checked={isActive}
								onChange={() => setIsActive((prev) => !prev)}
								className="service__switch"
							/>
						</div>
					</div>
					<Radio className="multiple__radios" checked value={2}>
						Brief
					</Radio>
					<Input
						className="service__input"
						placeholder="Type Question..."
						value={question}
						onChange={(e) => setQuestion(e.target.value)}
					/>
					<Input
						className="service__input"
						placeholder="أكتب سؤال..."
						dir="rtl"
						value={arQuestion}
						onChange={(e) => setArQuestion(e.target.value)}
					/>
					<div className="service__number">
						<label htmlFor="max-char">Max. Number of characters</label>
						<InputNumber
							value={maxNumberOfCharacter}
							onChange={(value) => {
								setMaxNumberOfCharacter(value);
							}}
							id="max-char"
						/>
					</div>
					<div className="service__copy-delete-required">
						<div className="d-flex align-items-center">
							<img
								src={copyIcon}
								onClick={copyQuestion}
								role={"button"}
								alt="copy"
								style={{
									cursor: selectedQuestion ? "pointer" : "not-allowed",
									opacity: selectedQuestion ? "1" : "0.5",
								}}
							/>
							<img
								src={deleteIcon}
								role={"button"}
								onClick={() => {
									setIsMenuOpen(false);
									setIsDeleteModalOpened(true);
								}}
								alt="delete"
								style={{
									cursor: selectedQuestion ? "pointer" : "not-allowed",
									opacity: selectedQuestion ? "1" : "0.5",
								}}
							/>
						</div>
						<div className="service__switch-container">
							<div>Required</div>
							<Switch
								checked={isRequired}
								onChange={() => setIsRequired((prev) => !prev)}
							/>
						</div>
					</div>
					<div className="service__button">
						<Button
							style={{
								width: "133px",
								height: "42px",
							}}
							type="primary"
							loading={isLoading}
							disabled={isDisabled}
							onClick={addNewQuestion}
						>
							{selectedQuestion ? "Edit" : "Submit"}
						</Button>
					</div>
				</div>
			</div>
			{isDeleteModalOpened && (
				<DeleteQuestionModal
					deleteModalVisibility={isDeleteModalOpened}
					selectedQuestion={selectedQuestion}
					getAllQuestions={getAllQuestions}
					onCancel={() => setIsDeleteModalOpened(false)}
				/>
			)}
		</>
	);
};

export default ParagraphQuestion;
