import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import chatIcon from "assets/Icons/chat.svg";
import notificationIcon from "assets/Icons/notifications.svg";
import logoutIcon from "assets/Icons/logout.svg";
import { logout } from "store/authorization";
import ROUTES from "constants/ROUTES";
import BidsterLogo from "assets/Images/logo.svg";

const Navbar = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { authorization } = useSelector((state) => state.auth);

	function logoutFun() {
		dispatch(logout());
		navigate(ROUTES.SIGNIN);
	}

	return (
		<div className="navbar">
			<Container>
				<div className="navbar__start">
					<img src={BidsterLogo} alt="bidster logo" />
				</div>
				<div className="navbar__end">
					<ul className="navbar__ul">
						<li className="item" role={"button"}>
							<img src={chatIcon} alt="chating" />
						</li>
						<li className="item" role={"button"}>
							<img src={notificationIcon} alt="notifications" />
						</li>
						<li className="item">
							<span>Hello,</span>
							<span>{authorization.name}</span>
						</li>
						<li className="item" onClick={logoutFun} role={"button"}>
							<img src={logoutIcon} alt="logout" />
						</li>
					</ul>
				</div>
			</Container>
		</div>
	);
};

export default Navbar;
