import React, { useState, useEffect } from "react";
import UploadIcon from "assets/Icons/upload.svg";
import { Button, Form, Input } from "antd";
import { baseUrl } from "services";
import TextArea from "antd/lib/input/TextArea";
import {
	addBlog,
	editBlog,
	uploadBlogBannerImage,
} from "modules/Pages/PagesModal/network";
import { toastError, toastSuccess } from "helpers/toasters";

function BlogBanner({ allData }) {
	const [mediaFormatAlert, updateMediaFormatAlert] = useState(null);
	const [loading, updateLoading] = useState([
		{
			place: "",
			status: false,
		},
	]);
	const [bannerImage, updateBannerImage] = useState({});
	const [bannerTitle, updateBannerTitle] = useState("");
	const [bannerText, updateBannerText] = useState("");
	const [allBlogData, updateAllBlogData] = useState({});
	useEffect(() => {
		updateBannerTitle(allData?.title);
		updateBannerText(allData?.description);
		updateBannerImage({
			path: allData?.imgPath,
			name: allData?.imgPath?.split("-image-")[1],
		});
		updateAllBlogData(allData);
	}, [allData]);

	const handleUploadBannerImage = (e) => {
		const isImage = e.target.files[0].type.includes("image");

		if (!isImage) {
			updateMediaFormatAlert("Please, upload image files only");
		} else {
			updateLoading({ place: "uploadBlogBannerImage", status: true });

			let imageDataFile = new FormData();
			imageDataFile.append(
				"imgPath",
				e.target.files[0],
				`-${e.target.files[0].type.split("/")[0]}-${e.target.files[0].name}`
			);
			uploadBlogBannerImage(
				imageDataFile,
				(success) => {
					updateLoading({ place: "", status: false });
					if (success.isSuccess) {
						let fileData = Object.assign(e.target.files[0]);
						fileData.path = success.data.path;
						updateBannerImage(fileData);
					}
				},
				(fail) => {
					updateLoading({ place: "", status: false });
				}
			);
		}
	};

	const onFinish = (values) => {
		let data = {
			title: values.bannerTitle,
			description: values.bannerText,
			imgPath: bannerImage.path,
			id: allData?._id,
		};
		if (data?.id) {
			editBlog(
				data,
				(success) => {
					updateLoading({ place: "", status: false });
					toastSuccess(success.message);
				},
				(fail) => {
					updateLoading({ place: "", status: false });
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		} else {
			addBlog(
				data,
				(success) => {
					updateLoading({ place: "", status: false });
					toastSuccess(success.message);
				},
				(fail) => {
					updateLoading({ place: "", status: false });
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		}
	};
	return (
		<div className="blog-banner">
			<div className="f-15 main-color fw-600">Blog Banner</div>
			<Form
				key={allBlogData?.title}
				onFinish={onFinish}
				name="basic"
				initialValues={{ bannerTitle, bannerText }}>
				<Form.Item
					name="bannerImage"
					colon={false}
					valuePropName={bannerImage}
					required
					requiredMark={false}
					rules={[
						{
							required: true,
							validator: (_, value) => {
								if (bannerImage?.path) {
									return Promise.resolve();
								} else {
									return Promise.reject(new Error("Please, upload the image"));
								}
							},
						},
					]}>
					<div className="bap-image-show d-flex align-items-center my-2">
						<a
							href={baseUrl + "/" + bannerImage?.path}
							target="_blank"
							without
							rel="noreferrer">
							{bannerImage?.name}
						</a>
					</div>
					<input
						type="file"
						id="bannerImage"
						className="d-none"
						onChange={handleUploadBannerImage}
					/>

					<Button
						loading={
							loading.place === "uploadBlogBannerImage" && loading.status
						}
						className="bap-outline-button cursor-pointer upload-button">
						<label htmlFor="bannerImage" className="cursor-pointer">
							<span>Upload Image</span>
							<img src={UploadIcon} alt="upload" />
						</label>
					</Button>
					{mediaFormatAlert && (
						<div className="mx-2 text-danger">{mediaFormatAlert}</div>
					)}
				</Form.Item>

				<Form.Item
					name="bannerTitle"
					colon={false}
					required
					requiredMark={false}
					rules={[
						{
							required: true,
							message: "Please, Enter The Title",
						},
					]}>
					<Input
						className="bap-input"
						placeholder={"title"}
						value={bannerTitle}
						onChange={(e) => updateBannerTitle(e.target.value)}
					/>
				</Form.Item>
				<Form.Item
					name="bannerText"
					colon={false}
					required
					requiredMark={false}>
					<TextArea
						className="bap-input"
						placeholder={"Banner Text"}
						value={bannerText}
						style={{ resize: "none", height: "125.5px" }}
						onChange={(e) => updateBannerText(e.target.value)}
					/>
				</Form.Item>
				<div className="controllings-Buttons d-flex justify-content-center">
					<Button
						htmlType="submit"
						type="primary"
						className="bap-primary-button bap-button"
						loading={loading.place === "save" && loading.status}>
						Save
					</Button>
				</div>
			</Form>
			<div className="divider my-4"></div>
		</div>
	);
}

export default BlogBanner;
