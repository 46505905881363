import { getResource, putResource, postResource } from "services";
import { PATHS, PATHS_PARAMS } from "constants/PATHS";

export function getAllFeesEquations(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getFeesEquations);
	getResource(path, onSuccess, onFail);
}

export function editFeesEquations(updates, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.editFeesEquations);
	putResource(path, { updates }, onSuccess, onFail);
}

export function getTokensPackages(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getTokenPackages, { isAdmin: true });
	getResource(path, onSuccess, onFail);
}

export function addTokenPackage(
	{ name, tokens, price, isActive },
	onSuccess,
	onFail
) {
	const data = {
		name,
		tokens,
		price,
		isActive,
	};
	const path = PATHS(PATHS_PARAMS.addTokenConversion);
	postResource(path, data, onSuccess, onFail);
}

export function editTokenPackage(
	{ name, tokens, price, isActive },
	tokenPackageId,
	onSuccess,
	onFail
) {
	const data = { name, tokens, price, isActive };
	const path =
		PATHS(PATHS_PARAMS.editTokenPackage) + `?tokenPackageId=${tokenPackageId}`;
	putResource(path, data, onSuccess, onFail);
}

export function getTokenConversion(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getTokenConversion);
	getResource(path, onSuccess, onFail);
}

export function editTokenConversion({ cost }, onSuccess, onFail) {
	const data = { cost };
	const path = PATHS(PATHS_PARAMS.editTokenConversion);
	putResource(path, data, onSuccess, onFail);
}
