import { PATHS, PATHS_PARAMS } from "constants/PATHS";
import {
	postResource,
	getResource,
	putResource,
	deleteResource,
	patchResource,
} from "services";

export function getBanner(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getBanner);
	getResource(path, onSuccess, onFail, true, true);
}

export function uploadBannerImage(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.uploadBannerImage);
	postResource(path, data, onSuccess, onFail, true, true);
}

export function postBanner(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.postNewBanner);
	postResource(path, data, onSuccess, onFail);
}

export function editBanner(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.editBanner);
	putResource(path, data, onSuccess, onFail);
}
export function getHomeServiceBanner(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getHomeServiceBanner);
	getResource(path, onSuccess, onFail, true, true);
}
export function uploadHomeServiceBannerImage(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.uploadHomeServiceBannerImage);
	postResource(path, data, onSuccess, onFail, true, true);
}
export function postHomeServiceBanner(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.postHomeServiceBanner);
	postResource(path, data, onSuccess, onFail);
}

export function editHomeServiceBanner(data, onSuccess, onFail) {
	const { bannerId } = data;
	const path = PATHS(PATHS_PARAMS.editHomeServiceBanner, { bannerId });
	putResource(path, data, onSuccess, onFail);
}
export function deleteHomeServiceBanner(data, onSuccess, onFail) {
	const { bannerId } = data;
	const path = PATHS(PATHS_PARAMS.deleteHomeServiceBanner, { bannerId });
	deleteResource(path, onSuccess, onFail);
}

export function getUtlsAndMore(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getUtlsAndMore);
	getResource(path, onSuccess, onFail, true, true);
}
export function uploadUtlsAndMoreImage(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.uploadUtlsAndMoreImage);
	postResource(path, data, onSuccess, onFail, true, true);
}

export function editUtls(data, onSuccess, onFail) {
	const { utilId } = data;
	const path = PATHS(PATHS_PARAMS.editUtls, { utilId });
	putResource(path, data, onSuccess, onFail);
}

export function addUtilities(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.addUtls);
	postResource(path, data, onSuccess, onFail);
}

export function deleteUtlsAPI(data, onSuccess, onFail) {
	const { utilId } = data;
	const path = PATHS(PATHS_PARAMS.deleteUtls, { utilId });
	deleteResource(path, onSuccess, onFail);
}
export function deactivateUtil(data, onSuccess, onFail) {
	const { utilId } = data;
	const path = PATHS(PATHS_PARAMS.deactivateUtil, { utilId });
	postResource(path, data, onSuccess, onFail, true, false);
}
export function getHowItWorks(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getHomeHowItWorks);
	getResource(path, onSuccess, onFail, true, true);
}
export function uploadHowItWorksVideo(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.uploadHowItWorksVideo);
	postResource(path, data, onSuccess, onFail, true, true);
}
export function edithomeHowItWorks(data, onSuccess, onFail) {
	const { howItWorksId } = data;
	const path = PATHS(PATHS_PARAMS.edithomeHowItWorks, { howItWorksId });
	putResource(path, data, onSuccess, onFail);
}
export function getQAndA(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getQAndA);
	getResource(path, onSuccess, onFail, true, true);
}

export function editQAndA(data, onSuccess, onFail) {
	const { faqId } = data;
	const path = PATHS(PATHS_PARAMS.editQAndA, { faqId });
	putResource(path, data, onSuccess, onFail);
}
export function deleteQAndAAPI(data, onSuccess, onFail) {
	const { faqId } = data;
	const path = PATHS(PATHS_PARAMS.deleteQAndA, { faqId });
	deleteResource(path, onSuccess, onFail);
}
export function addQAndAs(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.addQAndA);
	postResource(path, data, onSuccess, onFail);
}

export function deactiveteQandA(data, onSuccess, onFail) {
	const { faqId } = data;
	const path = PATHS(PATHS_PARAMS.editQAndA, { faqId });
	putResource(path, data, onSuccess, onFail, true, false);
}

export function getBlog(onSuccess, onFail) {
	const path = `${PATHS(PATHS_PARAMS.getBlogs)}`;
	getResource(path, onSuccess, onFail);
}

export function uploadBlogBannerImage(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.uploadBlogBannerImage);
	postResource(path, data, onSuccess, onFail, true, true);
}
export function editBlog(data, onSuccess, onFail) {
	const { id } = data;
	const path = PATHS(PATHS_PARAMS.editBlog, { id });
	patchResource(path, data, onSuccess, onFail);
}
export function addBlog(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.addBlog);
	postResource(path, data, onSuccess, onFail, true);
}
export function getArticle(data, onSuccess, onFail) {
	const { categoryId } = data;
	const path = PATHS(PATHS_PARAMS.getArticle, { categoryId, isAdmin: true });
	getResource(path, onSuccess, onFail);
}
export function getBlogCategories(onSuccess, onFail) {
	const path = `${PATHS(PATHS_PARAMS.blogCategories)}`;
	getResource(path, onSuccess, onFail);
}
export function getRoles(onSuccess, onFail) {
	const path = `${PATHS(PATHS_PARAMS.getRoles)}`;
	getResource(path, onSuccess, onFail);
}
export function sendArticlesViaEmail(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.sendArticlesViaEmail);
	postResource(path, data, onSuccess, onFail);
}
export function addNewArticle(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.addArticle);
	postResource(path, data, onSuccess, onFail);
}

export function addNewCategory(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.addBlogCategory);
	postResource(path, data, onSuccess, onFail);
}

export function editOldCategory(data, onSuccess, onFail) {
	const { blogCategoryId } = data;
	const path = PATHS(PATHS_PARAMS.editBlogCategory, { blogCategoryId });
	putResource(path, data, onSuccess, onFail);
}

export function deleteOldCategory(data, onSuccess, onFail) {
	const { id } = data;
	const path = PATHS(PATHS_PARAMS.deleteBlogCategory, { id });
	deleteResource(path, onSuccess, onFail);
}
export function editArticle(data, onSuccess, onFail) {
	const { articlesId } = data;
	const path = PATHS(PATHS_PARAMS.editArticle, { articlesId });
	putResource(path, data, onSuccess, onFail);
}

export function deleteArticle(data, onSuccess, onFail) {
	const { articleId } = data;
	const path = PATHS(PATHS_PARAMS.deleteArticle, { articleId });
	deleteResource(path, onSuccess, onFail);
}

export function getArticleById(data, onSuccess, onFail) {
	const { articleId } = data;
	const path = `${PATHS(PATHS_PARAMS.getArticleById, { articleId })}`;
	getResource(path, onSuccess, onFail);
}

export function uploadCategoryImg(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.uploadCategoryImg);
	postResource(path, data, onSuccess, onFail, true, true);
}

export function editBlogArticle(data, onSuccess, onFail) {
	const { articlesId, categoryId } = data;
	const path = PATHS(PATHS_PARAMS.editArticle, { articlesId, categoryId });
	putResource(path, data, onSuccess, onFail);
}
export function addBlogArticle(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.addBlogArticle);
	postResource(path, data, onSuccess, onFail);
}

export function getUtlDetails(data, onSuccess, onFail) {
	const { utilId } = data;
	const path = `${PATHS(PATHS_PARAMS.getUtilDetails, { utilId })}`;
	getResource(path, onSuccess, onFail);
}

export function deleteUtilDetails(data, onSuccess, onFail) {
	const { utilId } = data;
	const path = PATHS(PATHS_PARAMS.deleteUtilDetails, { utilId });
	deleteResource(path, onSuccess, onFail);
}

export function uploadUtlContactImage(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.uploadUtlContactImage);
	postResource(path, data, onSuccess, onFail, true, true);
}

export function addUtilContact(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.addUtilContact);
	postResource(path, data, onSuccess, onFail);
}
