import { useState, useEffect } from "react";
import { Table } from "antd";
import ContactUsModal from "./components/ContactUsModal";
import { getAllContactUs } from "../network";
import { toastError } from "helpers/toasters";
import CustomTooltip from "modules/components/CustomTooltip";
import { DownOutlined } from "@ant-design/icons";
import { getAllAdmins } from "modules/Pages/Dashboard/network";

const ContactUs = () => {
  const [isContactUsOpened, setIsContactUsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [data, setData] = useState([]);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getAllContactUs(
      (success) => {
        setIsLoading(false);
        setData(success.data);
      },
      (fail) => {
        setIsLoading(false);
        if (fail?.error.data) {
          toastError(fail.error.data);
        } else {
          toastError();
        }
      }
    );

    // getAllPros
    getAllAdmins(
      (success) => {
        setAdmins(success.data);
      },
      (fail) => {
        if (fail?.error.data) {
          toastError(fail.error.data);
        } else {
          toastError();
        }
      }
    );
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 100,
      align: "center",
      render: (name, data) => {
        return (
          <div
            role={"button"}
            style={{
              textDecoration: "underline",
            }}
            onClick={() => {
              if (admins.length) {
                selectContactUsData(data);
              }
            }}
          >
            <CustomTooltip count={25} text={name} />
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      align: "center",
      render: (data) => <CustomTooltip count={25} text={data} />,
    },
    {
      title: "message",
      dataIndex: "message",
      key: "message",
      width: 300,
      align: "center",
      render: (data) => <CustomTooltip count={30} text={data} />,
    },
    {
      title: "Status",
      dataIndex: "isResolved",
      key: "isResolved",
      width: 100,
      align: "center",

      filters: [
        { text: "New", value: false },
        { text: "Resolved", value: true },
      ],
      filterIcon: () => <DownOutlined />,
      onFilter: (value, record) => record.isResolved === value,
      render: (isResolved) => {
        return (
          <div
            style={{
              fontSize: "1rem",
              color: isResolved ? "#26AD5F" : "#FF0505",
              fontWeight: isResolved ? "bold" : "400",
            }}
          >
            {isResolved ? "Resolved" : "New"}
          </div>
        );
      },
    },
  ];

  function selectContactUsData(data) {
    setIsContactUsOpened(true);
    setSelectedData(data);
  }

  return (
    <>
      <section className="contact main-section">
        <header className="main-header">
          <h2>Contact Us</h2>
        </header>
        <div className="contact__container"></div>
        <Table
          dataSource={data}
          columns={columns}
          rowKey={(record) => record.projectId}
          bordered
          loading={isLoading}
          size={"middle"}
          scroll={{
            x: "max-content",
            scrollToFirstRowOnChange: true,
          }}
          pagination={{
            pageSize: 8,
          }}
        />
      </section>
      {isContactUsOpened && (
        <ContactUsModal
          isVisible={isContactUsOpened}
          onCancel={() => setIsContactUsOpened(false)}
          data={selectedData}
          setAllData={setData}
          admins={admins}
        />
      )}
    </>
  );
};

export default ContactUs;
