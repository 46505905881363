import { useState, useEffect } from "react";
import { Modal } from "antd";
import Spinner from "modules/Shared/Spinner";
import { Tab, Tabs } from "react-bootstrap";
import { postBiddersShortlistedQuotationsForSingleProject } from "../network";
import { useTranslation } from "react-i18next";
import { toastError } from "helpers/toasters";
import CustomTooltip from "modules/components/CustomTooltip";

const UsersModal = ({ onCancel, visible, project }) => {
	const [selectedStatus, setSelectedStatus] = useState(2);
	const [users, setUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const { projectId } = project;

	const { t } = useTranslation();

	useEffect(() => {
		setIsLoading(true);
		const payload = { projectId };
		if (selectedStatus === 2) {
			payload.status = [2, 3, 4, 5, 7, 8, 9];
		} else if (selectedStatus === 3) {
			payload.status = [3, 4, 5, 8, 9];
		} else {
			payload.status = [4, 5, 9];
		}

		postBiddersShortlistedQuotationsForSingleProject(
			payload,
			(success) => {
				setUsers(success.data);
				setIsLoading(false);
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	}, [projectId, selectedStatus]);

	function selectStatus(eventKey) {
		setSelectedStatus(+eventKey);
		setUsers([]);
	}

	return (
		<Modal
			className="users-modal"
			visible={visible}
			onCancel={onCancel}
			footer={false}
			centered
		>
			<div>
				<Tabs
					className="custom-tabs"
					defaultActiveKey={selectedStatus}
					id="uncontrolled-tab-example"
					justify
					onSelect={selectStatus}
				>
					<Tab eventKey={2} title={t("bidders")}>
						<ul className="users-modal__users">
							{!isLoading ? (
								selectedStatus === 2 && users?.length ? (
									users.map((user, _idx) => (
										<li className="item" key={_idx}>
											<CustomTooltip text={user.userName} count={20} />
										</li>
									))
								) : (
									<div className="no-users">
										<p> there are no Pros yet.</p>
									</div>
								)
							) : (
								<Spinner />
							)}
						</ul>
					</Tab>
					<Tab eventKey={3} title={t("shortlisting")}>
						<ul className="users-modal__users">
							{!isLoading ? (
								selectedStatus === 3 && users?.length ? (
									users.map((user, _idx) => (
										<li className="item" role="button" key={_idx}>
											<CustomTooltip text={user.userName} count={20} />
										</li>
									))
								) : (
									<div className="no-users">
										<p> There are no Pros.</p>
									</div>
								)
							) : (
								<Spinner />
							)}
						</ul>
					</Tab>
					<Tab eventKey={4} title={t("quotations")}>
						<ul className="users-modal__users">
							{!isLoading ? (
								selectedStatus === 4 && users?.length ? (
									users.map((user, _idx) => (
										<li className="item" role="button" key={_idx}>
											<CustomTooltip text={user.userName} count={20} />
										</li>
									))
								) : (
									<div className="no-users">there are no Pros</div>
								)
							) : (
								<Spinner />
							)}
						</ul>
					</Tab>
				</Tabs>
			</div>
		</Modal>
	);
};

export default UsersModal;
