import { useState } from "react";
import { Modal, Button } from "antd";
import { toastSuccess, toastError } from "helpers/toasters";
import { useTranslation } from "react-i18next";
import { postActivateUser, postActivateAdmin } from "../network";
import CustomTooltip from "modules/components/CustomTooltip";

const ActivateUserModal = ({
	setData,
	setUsers,
	user,
	onCancel,
	visible,
	isAdmin,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();
	const { userId, isUserActive } = user;

	function confirm() {
		setIsLoading(true);

		let payload = {
			isActive: !isUserActive,
		};

		if (isAdmin) {
			payload.adminId = userId;
			postActivateAdmin(
				payload,
				(success) => {
					setIsLoading(false);
					toastSuccess(success.message);
					setUsers((users) => {
						let filteredUsers = users.filter((item) => {
							if (item.userId === userId) {
								item.isUserActive = !isUserActive;
							}
							return user;
						});
						return filteredUsers;
					});

					setData((users) => {
						let filteredUsers = users.filter((item) => {
							if (item.userId === userId) {
								item.isUserActive = !isUserActive;
							}
							return user;
						});
						return filteredUsers;
					});

					onCancel();
				},
				(fail) => {
					onCancel();
					setIsLoading(false);
					if (fail?.data?.error) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		} else {
			payload.userId = userId;
			postActivateUser(
				payload,
				(success) => {
					toastSuccess(success.message);
					setIsLoading(false);
					onCancel();
					setUsers((users) => {
						let filteredUsers = users.filter((item) => {
							if (item.userId === userId) {
								item.isUserActive = !isUserActive;
							}
							return user;
						});
						return filteredUsers;
					});
				},
				(fail) => {
					setIsLoading(false);
					if (fail?.data?.error) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}

	return (
		<>
			<Modal
				className="custom-modal"
				onCancel={onCancel}
				visible={visible}
				footer={null}
				centered>
				<div className="custom-modal__text">
					{isUserActive ? t("areYouSureDiactivate") : t("areYouSureActivate")} "
					<CustomTooltip text={user.userName} count={15} />" ?
				</div>

				<div className="custom-modal__controllers">
					<Button
						loading={isLoading}
						className={isUserActive ? "danger-button" : "primary-button"}
						onClick={confirm}>
						{user.isUserActive ? t("diActivate") : t("activate")}
					</Button>
					<Button className="secondary-button" onClick={onCancel}>
						cancel
					</Button>
				</div>
			</Modal>
		</>
	);
};

export default ActivateUserModal;
