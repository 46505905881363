const ROUTES = {
	HOME: "admin",
	SIGNIN: "/",
	PAGES: "pages",
	DASHBOARD: "dashboard",
	TEMPLATES: "templates",
	TOKENS: "tokens",
	TRANSACTIONS: "transactions",
	PROJECT_STATS: "project-stats",
	INBOX: "inbox",
	// pages
	PAGES_LANDING: "landing-page",
	PAGES_UTILITIES: "utilites-more",
	BLOGS: "blogs",
	// dashboard
	PROJECTS: "projects",
	PROPERTY_OWNER: "property-owner",
	PROS: "pros",
	SUPER_ADMIN: "super-admin",
	// templates
	SERVICE: "service",
	QUOTATIONS: "quotations",
	TERMSANDCONDITIONS: "terms-conditions",
	PRIVACYANDPOLICY: "privacy-policy",

	// tokens
	FEESE_QUATION: "fees-equation",
	TOKEN_CONVERSION: "token-conversion",
	TOKEN_PACKAGES: "token-packages",
	// transactions
	MONTHLY: "monthly",
	ANNUALY: "annualy",
	// project-stats
	BIDDING_PERFORMANCE: "bidding-performance",
	HIRING_PERFORMANCE: "hiring-performance",
	// inbox
	CONTACT_US: "contactus",
	FEEDBACK: "feedback",
	NOT_FOUND: "*",
};
export const homeRoute =
	ROUTES.HOME + "/" + ROUTES.PAGES + "/" + ROUTES.PAGES_LANDING;

export default ROUTES;
