import React, { useState } from "react";
import { toastSuccess } from "helpers/toasters";
import { deleteUtilDetails } from "modules/Pages/PagesModal/network";
import DeleteModal from "modules/Pages/PagesModal/Tabs/Blogs/Components/DeleteModal";
import closeIcon from "../../../../../../assets/Icons/CloseIcon.svg";
import { baseUrl } from "services";
function ProRowCard({ pro, getUtilDetailsFunction }) {
	const [isDeleteModalVis, updateIsDeleteModalVis] = useState(false);
	const deleteUser = () => {
		let data = {
			utilId: pro.UtilDetailsId,
		};
		deleteUtilDetails(
			data,
			(success) => {
				if (success.isSuccess) {
					toastSuccess(success.message);
				}
				getUtilDetailsFunction();
			},
			(fail) => {}
		);
	};
	return (
		<div className="d-flex proCard justify-content-between align-items-start">
			<div className="d-flex  align-items-center">
				<img
					className="avatar mx-2"
					src={baseUrl + "/" + pro.image}
					alt="avatar"
				/>
				<div>
					<div className="main-color fw-700 f-21">{pro.name}</div>
					<>
						{pro.socialLinks?.map((link) => {
							return <span className="me-2">{link}</span>;
						})}
					</>
					<div>{pro.website}</div>
					<div>{pro.email}</div>
					<div>{pro.contacts}</div>
					<div>{pro.anotherContact}</div>
				</div>
			</div>
			<img
				src={closeIcon}
				alt="closeIcon"
				className="cursor-pointer"
				onClick={() => {
					updateIsDeleteModalVis(true);
				}}
			/>
			<DeleteModal
				visible={isDeleteModalVis}
				onCancel={() => {
					updateIsDeleteModalVis(false);
				}}
				addDeleteDetails={{
					message: `Are you sure you want to delete this contact?`,
					deleteFunction: deleteUser,
					messageStyle: {
						color: "#978F8F",
						textAlign: "center",
						fontSize: "18px",
					},
				}}
			/>
		</div>
	);
}

export default ProRowCard;
