import { toastError } from "helpers/toasters";
import { useState, useEffect } from "react";
import { getMonthlyAndAnnualTransaction, getAllServiceTypes } from "../network";
import { DatePicker, Select, Spin } from "antd";
import moment from "moment/moment";

const Annualy = ({ mode }) => {
	const [serviceTypeIds, setServiceTypeIds] = useState([]);
	const [selectedServiceTypeId, setSelectedServiceTypeId] = useState(null);
	const [selectedDate, setSelectedDate] = useState([]);
	const [selectedDatePicker, setSelectedDatePicker] = useState([]);
	const [transData, setTransData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setSelectedDate([]);
		setSelectedDatePicker([]);
	}, [mode]);

	useEffect(() => {
		setIsLoading(true);
		if (selectedServiceTypeId) {
			let payload = {
				serviceTypeId: selectedServiceTypeId,
			};
			if (selectedServiceTypeId === "62a07b1ecd9edbc8e7194164")
				payload.serviceTypeId = null;
			const [fromDate, toDate] = selectedDate;
			payload.fromDate = fromDate;
			payload.toDate = toDate;
			payload.mode = mode;

			getMonthlyAndAnnualTransaction(
				payload,
				(success) => {
					setIsLoading(false);
					setTransData(success.data);
				},
				(fail) => {
					setIsLoading(false);
					if (fail?.data?.error) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}, [selectedDate, selectedServiceTypeId, mode]);

	useEffect(() => {
		setIsLoading(true);
		getAllServiceTypes(
			(success) => {
				setServiceTypeIds((prev) => {
					let arr = [];
					success.data.forEach((element, idx) => {
						const { serviceType, _id } = element;
						// if (idx === success.data.length - 1)
						arr.push({ label: serviceType, value: _id });
					});
					return arr;
				});
				setSelectedServiceTypeId(success.data[0]._id);
				setIsLoading(false);
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	}, []);

	const handleChangeServiceType = (value) => {
		setSelectedServiceTypeId(value);
	};

	const changeDateRange = (date) => {
		if (!date) {
			setSelectedDatePicker([]);
			setSelectedDate([]);
		} else {
			const [from, to] = date;
			setSelectedDatePicker([from, to]);
			setSelectedDate([moment(from).toISOString(), moment(to).toISOString()]);
		}
	};

	const disabledDate = (current) => {
		return current && current > Date.now();
	};

	return (
		<>
			<header className="main-header">
				{mode === "month" ? <h2>Monthly</h2> : <h2>Annual</h2>}
			</header>

			<section className="transactions p-4 main-section box-shadow">
				{mode === "month" && (
					<DatePicker.RangePicker
						defaultPickerValue={selectedDatePicker}
						disabledDate={disabledDate}
						onChange={changeDateRange}
						className="transactions__date"
						picker={"month"}
						allowClear
					/>
				)}
				{mode !== "month" && (
					<DatePicker.RangePicker
						defaultPickerValue={selectedDatePicker}
						disabledDate={disabledDate}
						onChange={changeDateRange}
						className="transactions__date"
						picker={"year"}
						allowClear
					/>
				)}
				<h5 className="transactions__head">Bidding - Service Type - Area</h5>
				<section className="transactions__section box-shadow">
					<Select
						className="transactions__select"
						onChange={handleChangeServiceType}
						options={serviceTypeIds}
						dropdownMatchSelectWidth={250}
						style={{
							width: 250,
						}}
						value={selectedServiceTypeId && selectedServiceTypeId}
					/>
					<table>
						{isLoading ? (
							<Spin className="spinner" />
						) : (
							<>
								<tr>
									<td className="td-head">Serivces</td>
									{transData?.length &&
										transData?.map(({ serviceName }, _idx) => {
											return (
												<td className="td-head" colSpan={3} key={_idx}>
													{serviceName}
												</td>
											);
										})}
								</tr>
								<tr>
									<td className="td-head">Areas</td>
									{transData?.length &&
										transData.map(({ area }, _) => {
											return area.map(({ from, to }, _idx) => (
												<td key={_ + _idx}>
													{from ? from : "<"} {from && to && " - "}{" "}
													{to ? to : "<"}
												</td>
											));
										})}
								</tr>
								<tr>
									<td className="td-head" rowSpan={2}>
										Projects #
									</td>
									{transData?.length &&
										transData.map(({ area }) => {
											return area.map(({ projectsCount }, _idx) => (
												<td key={_idx}>{projectsCount}</td>
											));
										})}
								</tr>
								<tr>
									{transData?.length &&
										transData.map(({ totalProjects }, _idx) => {
											return (
												<td colSpan={3} key={_idx}>
													{totalProjects}
												</td>
											);
										})}
								</tr>
								<tr>
									<td className="td-head" rowSpan={2}>
										Transactions/EGP
									</td>
									{transData?.length &&
										transData.map(({ area }) => {
											return area.map(({ cost }, _idx) => (
												<td key={_idx}>{cost}</td>
											));
										})}
								</tr>
								<tr>
									{transData?.length &&
										transData?.map(({ totalCosts }, _idx) => {
											return (
												<td colSpan={3} key={_idx}>
													{totalCosts}
												</td>
											);
										})}
								</tr>
							</>
						)}
					</table>
				</section>
			</section>
		</>
	);
};

export default Annualy;
