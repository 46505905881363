import { PATHS, PATHS_PARAMS } from "constants/PATHS";
import { getResource } from "services";

export function isActiveUser(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.logoutAdmin);
	getResource(path, onSuccess, onFail);
}

export function getUserRoles(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.userRoles);
	getResource(path, onSuccess, onFail);
}
