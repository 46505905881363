import { useState, useEffect } from "react";
import { Modal, Button, Select, Input, Radio } from "antd";
import { editContactUsStatus } from "../../network";
import { toastError } from "helpers/toasters";

const ContactUsModal = ({ isVisible, onCancel, data, setAllData, admins }) => {
	const [isDisabled, setIsDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isResolved, setIsResolved] = useState(false);
	const [selectedAdmin, setSelectedAdmin] = useState(null);
	const [disableSelect, setDisableSelect] = useState(false);

	useEffect(() => {
		if (!isResolved) {
			setDisableSelect(true);
			setIsDisabled(true);
		} else {
			setDisableSelect(false);
		}

		if (selectedAdmin && isResolved) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [isResolved, selectedAdmin]);

	useEffect(() => {
		if (data.isResolved) {
			setDisableSelect(true);
			setIsDisabled(true);
		}
	}, [data, selectedAdmin]);

	function cancelModal() {
		onCancel();
	}

	function selectUser(value) {
		setSelectedAdmin(value);
	}

	function changeIsResolved(e) {
		setIsResolved(e.target.value);
	}

	function onSubmit() {
		setIsLoading(true);
		const payload = {
			isResolved,
			answeredBy: selectedAdmin,
			id: data.id,
		};
		editContactUsStatus(
			payload,
			(success) => {
				setIsLoading(false);
				onCancel();
				setAllData((prev) => {
					let filteredData = prev.map((item) => {
						if (item.id === data.id) {
							item.isResolved = isResolved;
						}
						return item;
					});
					return filteredData;
				});
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	return (
		<Modal
			visible={isVisible}
			onCancel={cancelModal}
			className="contact-modal"
			centered
			width={"50vw"}
			bodyStyle={{
				minHeight: "50vh",
			}}
			footer={null}
		>
			<header className="contact-modal__header">
				<h5>Inquiry {data.inquiry}</h5>
			</header>
			<div className="contact-modal__desc">
				<div className="name">{data.name}</div>
				<div className="email">{data.email}</div>
			</div>
			<Input.TextArea
				className="contact-modal__area"
				value={data.message}
				style={{
					resize: "none",
				}}
			/>
			<div className="contact-modal__radios">
				<Radio.Group
					disabled={data.isResolved}
					defaultValue={data.isResolved}
					onChange={changeIsResolved}
				>
					<Radio value={false}>Pending</Radio>
					<Radio value={true}>Case Closed</Radio>
				</Radio.Group>
			</div>
			<div className="contact-modal__select">
				<div className="by">By:</div>
				<Select
					className="select"
					placeholder="Select Admin"
					dropdownMatchSelectWidth={250}
					allowClear
					value={data?.answeredBy}
					onChange={selectUser}
					disabled={disableSelect}
				>
					{admins?.length
						? admins.map((admin, _idx) => {
								return (
									<Select.Option value={admin.userId} key={_idx}>
										{admin.name}
									</Select.Option>
								);
						  })
						: null}
				</Select>
			</div>
			<div className="contact-modal__btn">
				<Button
					style={{
						width: "113px",
						height: "41px",
					}}
					className="submit-btn"
					type="primary"
					disabled={isDisabled}
					loading={isLoading}
					onClick={onSubmit}
				>
					Submit
				</Button>
			</div>
		</Modal>
	);
};

export default ContactUsModal;
