import { useState, useEffect } from "react";
import { Table, Radio, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import { postAllHomeOwner } from "../network";
import { toastError } from "helpers/toasters";
import { searchOnTable } from "helpers/searchOnTable";
import SearchInTable from "modules/components/SearchInTable";
import CustomTooltip from "modules/components/CustomTooltip";
import ProjectsModal from "../components/ProjectsModal";
import ActivateUserModal from "../components/ActivateUserModal";
// import TableSliderFilter from "modules/Shared/TableSliderFilter";
import moment from "moment";

const PropertyOwner = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [homeOwnerData, setHomeOwnerData] = useState([]);
	const [showingData, setShowingData] = useState([]);
	const [isProjectModalVisible, setIsProjectModalVisible] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [isActiveUserModalVisible, setIsActiveUserModalVisible] =
		useState(false);
	const [searchVal, setSearchVal] = useState("");
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);
	const { t } = useTranslation();

	useEffect(() => {
		setIsLoading(true);
		postAllHomeOwner(
			{ fromDate, toDate },
			(success) => {
				setIsLoading(false);
				setHomeOwnerData(success.data);
				setShowingData(success.data);
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	}, [fromDate, toDate]);

	const columns = [
		{
			title: t("name"),
			dataIndex: "userName",
			key: "userName",
			align: "center",
			width: 200,
			fixed: "left",
			render: (data) => {
				return <CustomTooltip text={data} count={25} />;
			},
		},
		{
			title: t("email"),
			dataIndex: "userEmail",
			key: "userEmail",
			align: "center",
			width: 200,
			render: (data) => {
				return <CustomTooltip text={data} count={25} />;
			},
		},
		{
			title: t("mobile"),
			dataIndex: "userPhone",
			key: "userPhone",
			align: "center",
			width: 200,
			render: (data) => {
				return <CustomTooltip text={data} count={25} />;
			},
		},
		{
			title: t("projects"),
			dataIndex: "projects",
			key: "projects",
			align: "center",
			width: 100,
			sorter: (a, b) => a.projects.length - b.projects.length,
			render: (data, record) => {
				return (
					<div
						style={{
							textDecoration: data.length && "underline",
						}}
						role={"button"}
						onClick={() => {
							if (data.length) {
								openProjectsModal(record);
							}
						}}
					>
						{data.length}
					</div>
				);
			},
		},
		{
			title: t("signUpDate"),
			dataIndex: "signUpDate",
			key: "signUpDate",
			align: "center",
		},
		{
			title: t("status"),
			dataIndex: "status",
			key: "status",
			align: "center",
			width: 100,
			filters: [
				{ text: "Active", value: 1 },
				{ text: "In Active", value: 0 },
			],
			filterIcon: () => <DownOutlined />,
			onFilter: (value, record) => record.status === value,
			render: (status) => {
				return (
					<>
						{
							<>
								{status ? (
									<strong>{t("active")}</strong>
								) : (
									<div
										style={{
											color: "#707070",
										}}
									>
										{t("inActive")}
									</div>
								)}
							</>
						}
					</>
				);
			},
		},
		{
			title: t("activity"),
			dataIndex: "isUserActive",
			key: "isUserActive",
			align: "center",
			width: 50,
			filters: [
				{ text: "Activated", value: true },
				{ text: "De Acitvated", value: false },
			],
			filterIcon: () => <DownOutlined />,
			onFilter: (value, record) => record.isUserActive === value,
			render: (isActive, userData) => {
				return (
					<Radio
						checked
						onClick={() => openActivateUser(userData)}
						className={!isActive ? "in-active-radio" : "active-radio"}
					/>
				);
			},
		},
	];

	function openProjectsModal(user) {
		setSelectedUser(user);
		setIsProjectModalVisible(true);
	}

	function openActivateUser(user) {
		setIsActiveUserModalVisible(true);
		setSelectedUser(user);
	}

	function changeDateFilter(date) {
		if (!date) {
			setFromDate(null);
			setToDate(null);
			return;
		}
		const [from, to] = date;
		setFromDate(moment(from).toISOString());
		setToDate(moment(to).toISOString());
	}

	return (
		<div>
			<header className="main-header">
				<h2>{t("propertyOwners")}</h2>
			</header>

			<div
				className="search-date"
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div className="search-date__signup-date">
					<div>Filter By Sign Up:</div>
					<DatePicker.RangePicker
						className="date"
						onChange={changeDateFilter}
						format={(value) => moment(value).format("DD-MM-YYYY")}
						size="middle"
					/>
				</div>

				<SearchInTable
					searchVal={searchVal}
					setSearchVal={setSearchVal}
					onSubmit={() =>
						searchOnTable(
							searchVal,
							"userName",
							setIsLoading,
							homeOwnerData,
							setShowingData
						)
					}
					className="search-date__search"
				/>
			</div>

			<Table
				dataSource={showingData}
				columns={columns}
				rowKey={(record) => record.userId}
				bordered
				loading={isLoading}
				size={"middle"}
				scroll={{
					x: "max-content",
					scrollToFirstRowOnChange: true,
				}}
				pagination={{
					pageSize: 10,
				}}
			/>
			{isActiveUserModalVisible && (
				<ActivateUserModal
					visible={isActiveUserModalVisible}
					onCancel={() => setIsActiveUserModalVisible(false)}
					user={selectedUser}
					setUsers={setHomeOwnerData}
				/>
			)}
			{isProjectModalVisible && (
				<ProjectsModal
					visible={isProjectModalVisible}
					onCancel={() => setIsProjectModalVisible(false)}
					projects={selectedUser.projects}
					userName={selectedUser.userName}
				/>
			)}
		</div>
	);
};

export default PropertyOwner;
