import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MultipleChoises from "../Components/MultipleChoises";
import ParagraphQuestion from "../Components/ParagraphQuestion";

const Service = () => {
  const [serviceName, setServiceName] = useState(null);
  const {
    authorization: { serivces },
  } = useSelector((state) => state.auth);

  const { serviceId } = useParams();

  useEffect(() => {
    let serviceName = serivces.find((service) => service._id === serviceId);
    setServiceName(serviceName?.name);
  }, [serivces, serviceId]);

  return (
    <section className="main-section service">
      <header className="main-header">
        <h2>{serviceName}</h2>
      </header>
      <div className="service box-shadow">
        <MultipleChoises serviceId={serviceId} />
        {/* Paragraph Section */}
        <ParagraphQuestion serviceId={serviceId} />
      </div>
    </section>
  );
};

export default Service;
