import React, { useEffect, useState } from "react";
import { Checkbox, Tabs, Dropdown, Menu, Button } from "antd";
import arrowDown from "assets/Icons/arrow-down-dropdown.svg";
import hideEye from "assets/Icons/hide-eye.svg";
import showEye from "assets/Icons/show-eye.svg";
import garbage from "assets/Icons/garbage.svg";
import plusCircle from "assets/Icons/circle-plus.svg";
import plusCircleBox from "assets/Icons/circle-plus-box.svg";
import {
	addNewCategory,
	deleteArticle,
	deleteOldCategory,
	editArticle,
	editOldCategory,
	getArticle,
	getBlogCategories,
	getRoles,
	sendArticlesViaEmail,
} from "modules/Pages/PagesModal/network";
import AddNewModal from "./AddNewModal";
import { toastError, toastSuccess } from "helpers/toasters";
import DeleteModal from "./DeleteModal";

function BlogArticles({ getSelectedArticle, selectedArticle, recallArticles }) {
	const [activeKey, updateActiveKey] = useState("0");
	const [categories, updateCategories] = useState([]);
	const [articles, updateArticles] = useState([]);
	const [roles, updateRoles] = useState([]);
	const [selectedRoles, updateSelectedRoles] = useState([]);
	const [isAddNewModalVis, updateIsAddModalVis] = useState(false);
	const [isDeleteModalVis, updateIsDeleteModalVis] = useState(false);
	const [addDeleteDetails, updateAddDeleteDetails] = useState({});
	const [loading, setLoading] = useState(false);
	const [selectedCategory, updateSelectedCategory] = useState(null);

	const onRoleChange = (checkedValues, articleId) => {
		let allSelectedRoles = [...selectedRoles];
		var isArticleExist = false;
		allSelectedRoles.forEach((role, roleIndex) => {
			if (role.articleId === articleId) {
				isArticleExist = roleIndex;
			}
		});
		if (isArticleExist !== false) {
			allSelectedRoles[isArticleExist] = { articleId, idsList: checkedValues };
		} else {
			allSelectedRoles.push({ articleId, idsList: checkedValues });
		}
		allSelectedRoles = allSelectedRoles.filter(
			(roles) => roles.idsList.length !== 0
		);
		updateSelectedRoles(allSelectedRoles);
	};

	const sendArticles = () => {
		let data = { articlesList: selectedRoles };
		sendArticlesViaEmail(
			data,
			(success) => {
				setLoading(false);
				toastSuccess(success.message);
			},
			(fail) => {
				setLoading(false);
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	};

	const categoryMenu = (
		<Menu
			items={[
				{
					key: "0",
					label: (
						<div
							onClick={() => {
								updateAddDeleteDetails({
									title: "Category Name",
									saveFunction: editCategory,
									selected: selectedCategory,
								});
								updateIsAddModalVis(true);
							}}
							className="px-2"
						>
							Edit
						</div>
					),
				},
				{
					type: "divider",
				},
				{
					key: "1",
					danger: true,
					label: (
						<div
							onClick={() => {
								updateAddDeleteDetails({
									message: "Are you sure you want to delete this category?",
									saveFunction: deleteCategory,
									selected: selectedCategory,
								});
								updateIsDeleteModalVis(true);
							}}
							className="px-2"
						>
							Delete
						</div>
					),
				},
			]}
		/>
	);

	const articlesNode = (
		<div className="d-flex justify-content-baseline">
			<div className="articles-section d-flex flex-1 flex-column">
				{articles.map((article, index) => {
					return (
						<div
							className="d-flex justify-content-between flex-1 my-2 article-row"
							key={index}
						>
							<div
								onClick={() => {
									getSelectedArticle(article._id);
								}}
								className={`${
									selectedArticle === article._id
										? "text-underline article-title"
										: "article-title"
								}`}
							>
								{article.title}
							</div>
							<div>
								<Checkbox.Group
									options={roles}
									onChange={(checkedValue) => {
										onRoleChange(checkedValue, article._id);
									}}
								/>
								<img
									className="mx-2 cursor-pointer"
									onClick={() => {
										updateAddDeleteDetails({
											message: `Are you sure you want to ${
												article.isActive ? "deactivate" : "activate"
											} this article?`,
											saveFunction: ActiveDeactiveArticle,
											selected: article,
										});
										updateIsDeleteModalVis(true);
									}}
									src={article.isActive ? hideEye : showEye}
									alt="hideEye"
								/>
								<img
									className="mx-2 cursor-pointer"
									src={garbage}
									alt="garbage"
									onClick={() => {
										updateAddDeleteDetails({
											message: "Are you sure you want to delete this article?",
											saveFunction: deleteArticleData,
											selected: article,
										});
										updateIsDeleteModalVis(true);
									}}
								/>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);

	const deleteArticleData = (articleId) => {
		const data = {
			articleId,
		};
		deleteArticle(
			data,
			(success) => {
				toastSuccess(success.message);
				let data = { categoryId: categories[parseInt(activeKey)]._id };

				getArticle(
					data,
					(success) => {
						updateArticles(success.data);
						getAllBlogCats();
					},
					(fail) => {}
				);
			},
			(fail) => {
				setLoading(false);
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	};

	const ActiveDeactiveArticle = (id, isActive) => {
		let data = { articlesId: id, isActive: !isActive };
		editArticle(
			data,
			(success) => {
				toastSuccess(success.message);
				getAllBlogCats();
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	};

	const addCategory = (category) => {
		let data = { category };
		addNewCategory(
			data,
			(success) => {
				toastSuccess(success.message);
				getAllBlogCats();
			},
			(fail) => {
				setLoading(false);
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	};

	const editCategory = (category, categoryId, isActive) => {
		let data = { category, blogCategoryId: categoryId, isActive };
		editOldCategory(
			data,
			(success) => {
				toastSuccess(success.message);
				getAllBlogCats();
			},
			(fail) => {
				setLoading(false);
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	};

	const deleteCategory = (categoryId) => {
		let data = { id: categoryId };
		deleteOldCategory(
			data,
			(success) => {
				toastSuccess(success.message);
				getAllBlogCats();
			},
			(fail) => {
				setLoading(false);
				if (fail?.data?.error) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	};

	const getAllBlogCats = () => {
		getBlogCategories(
			(success) => {
				updateActiveKey("0");
				updateCategories(success.data);
			},
			(fail) => {}
		);
	};

	const getAllRoles = () => {
		getRoles(
			(success) => {
				let modifiedRoles = [];
				const allRoles = success.data;
				allRoles.forEach((role) => {
					modifiedRoles.push({
						label: role.accountType === "Pro" ? "Pros" : "Property Owners",
						value: role._id,
					});
				});
				updateRoles(modifiedRoles);
			},
			(fail) => {}
		);
	};

	useEffect(() => {
		getAllBlogCats();
		getAllRoles();
	}, []);

	useEffect(() => {
		if (categories.length && activeKey) {
			let data = { categoryId: categories[parseInt(activeKey)]?._id };
			getArticle(
				data,
				(success) => {
					updateArticles(success.data);
				},
				(fail) => {}
			);
		}
	}, [activeKey, categories, recallArticles]);

	return (
		<div className="blog-article">
			<div className="f-15 main-color fw-600">Blog Articles</div>
			<div className="d-flex flex-1 align-items-baseline">
				<Tabs
					tabPosition={"top"}
					style={{
						maxHeight: "220px",
						overflow: "auto",
						width: "-webkit-fill-available",
					}}
					onTabClick={(key) => {
						updateActiveKey(String(key));
					}}
					activeKey={activeKey}
					items={categories?.map((category, i) => {
						const index = String(i);
						return {
							label: (
								<>
									<>{category.category}</>
									<Dropdown
										onClick={() => {
											updateSelectedCategory(category);
										}}
										trigger={["click"]}
										overlay={categoryMenu}
									>
										<img src={arrowDown} alt="arrowDown" className="mx-2" />
									</Dropdown>
								</>
							),
							key: index,
							children: articlesNode,
						};
					})}
					className="d-flex flex-1"
				/>
				<div className="d-flex flex-column  align-items-center">
					<img
						src={plusCircle}
						alt="plus"
						className="cursor-pointer"
						style={{ width: "fitContent" }}
						onClick={() => {
							updateIsAddModalVis(true);
							updateAddDeleteDetails({
								title: "Category Name",
								saveFunction: addCategory,
							});
							updateSelectedCategory(null);
						}}
					/>
					<img
						src={plusCircleBox}
						onClick={() => {
							updateIsAddModalVis(true);
							updateAddDeleteDetails({
								title: "Article Name",
								saveFunction: getSelectedArticle,
								status: "new",
							});
							updateSelectedCategory(null);
							getSelectedArticle(null);
						}}
						alt="plus"
						className="my-4 cursor-pointer"
					/>
				</div>
			</div>
			<div className="text-center">
				<Button
					className={`${
						selectedRoles.length !== 0 ? "bap-primary-button" : ""
					} bap-button`}
					loading={loading}
					onClick={sendArticles}
					disabled={selectedRoles.length === 0}
				>
					Confirm
				</Button>
			</div>
			{isAddNewModalVis && (
				<AddNewModal
					visible={isAddNewModalVis}
					onCancel={() => {
						updateIsAddModalVis(false);
						updateAddDeleteDetails({});
						getAllBlogCats();
						getAllRoles();
					}}
					addDeleteDetails={addDeleteDetails}
				/>
			)}

			{isDeleteModalVis && (
				<DeleteModal
					visible={isDeleteModalVis}
					onCancel={() => {
						updateAddDeleteDetails({});
						// getAllBlogCats();
						// getAllRoles();
						updateIsDeleteModalVis(false);
					}}
					getAllBlogCats={() => getAllBlogCats()}
					getAllRoles={() => getAllRoles()}
					addDeleteDetails={addDeleteDetails}
				/>
			)}
		</div>
	);
}

export default BlogArticles;
