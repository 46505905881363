import { useState, useEffect } from "react";
import { Form, Input, Button, Radio, Switch } from "antd";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import UploadIcon from "assets/Icons/upload.svg";
import plus from "assets/Icons/plus.svg";
import {
	editUtls,
	uploadUtlsAndMoreImage,
	getUtlsAndMore,
	deleteUtlsAPI,
	deactivateUtil,
	addUtilities,
} from "modules/Pages/PagesModal/network";
import { baseUrl } from "services";
import { toastError, toastSuccess } from "helpers/toasters";

function LandingPageUtilitiesAndMore() {
	const [image, updateImage] = useState(null);
	const [icon, updateIcon] = useState(null);
	const [arabicTitle, setArabicTitle] = useState("");
	const [englishTitle, setEnglishTitle] = useState("");
	const [loadingDelete, updateLoadingDelete] = useState(false);
	const [loadingSave, updateLoadingSave] = useState(false);
	const [mediaFormatAlert, updateMediaFormatAlert] = useState(false);
	const [loadingImage, updateLoadingImage] = useState(false);
	const [loadingIcon, updateLoadingIcon] = useState(false);
	const [allData, updateAllData] = useState(null);
	const [sliderIndex, updateSliderIndex] = useState(0);
	const [indexList, updateIndexList] = useState([]);
	const [sortValue, setSortValue] = useState(1);
	const [isActive, updateIsActive] = useState(false);
	const { t } = useTranslation();
	const getAllUtls = (sliderIndexVar) => {
		getUtlsAndMore(
			(success) => {
				let data = success.data[sliderIndexVar ? sliderIndexVar : 0];
				updateAllData(data);
				setEnglishTitle(data.title.en);
				setArabicTitle(data.title.ar);
				updateImage({ path: data.image, name: data.image.split("-image-")[1] });
				updateIcon({ path: data.icon, name: data.icon.split("-image-")[1] });
				updateIsActive(data.isActive);
				updateIndexList(success.data);
				setSortValue(data.index);
			},
			(fail) => {}
		);
	};

	useEffect(() => {
		getAllUtls();
		// eslint-disable-next-line
	}, []);
	const handleUploadImageIcon = (e) => {
		const isImage = e.target.files[0].type.includes("image");
		if (!isImage) {
			updateMediaFormatAlert({
				place: e.target.id,
				msg: "* Please, upload an image",
			});
		} else {
			updateMediaFormatAlert(null);
			if (e.target.id === "image") {
				updateLoadingImage(true);
			} else {
				updateLoadingIcon(true);
			}

			let imageDataFile = new FormData();
			imageDataFile.append(
				"image",
				e.target.files[0],
				`-${e.target.files[0].type.split("/")[0]}-${e.target.files[0].name}`
			);

			uploadUtlsAndMoreImage(
				imageDataFile,
				(success) => {
					if (e.target.id === "image") {
						updateLoadingImage(false);
					} else {
						updateLoadingIcon(false);
					}
					if (success.isSuccess) {
						let fileData = Object.assign(e.target.files[0]);
						fileData.path = success.data.path;
						if (e.target.id === "image") {
							updateImage(fileData);
						} else {
							updateIcon(fileData);
						}
					}
				},
				(fail) => {
					if (e.target.id === "image") {
						updateLoadingImage(false);
					} else {
						updateLoadingIcon(false);
					}
				}
			);
		}
	};
	const deleteUtls = () => {
		if (allData._id) {
			let data = { utilId: allData._id };
			updateLoadingDelete(true);

			deleteUtlsAPI(
				data,
				(success) => {
					updateLoadingDelete(false);
					toastSuccess(success.message);
					if (sliderIndex !== 0) {
						getAllUtls(sliderIndex - 1);
					} else {
						getAllUtls(sliderIndex + 1);
					}
				},
				(fail) => {
					updateLoadingDelete(false);

					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		} else {
			updateSliderIndex(sliderIndex - 1);
			getAllUtls(sliderIndex - 1);
		}
	};

	const addUtls = () => {
		let indexListVar = [...indexList];
		let newUtls = {
			icon: "",
			image: "",
			index: indexListVar.length,
			isActive: true,
			title: { en: "", ar: "" },
		};
		indexListVar.push(newUtls);
		updateIndexList(indexListVar);
		updateSliderIndex(indexListVar.length - 1);
		setSortValue(indexListVar.length - 1);
	};

	useEffect(() => {
		if (indexList.length) {
			setEnglishTitle(indexList[sliderIndex].title.en);
			setArabicTitle(indexList[sliderIndex].title.ar);
			updateImage(
				Object.keys(indexList[sliderIndex].image)
					? {
							path: indexList[sliderIndex].image,
							name: indexList[sliderIndex]?.image?.split("-image-")[1],
					  }
					: indexList[sliderIndex].image
			);
			updateIcon(
				Object.keys(indexList[sliderIndex].icon)
					? {
							path: indexList[sliderIndex].icon,
							name: indexList[sliderIndex]?.icon?.split("-image-")[1],
					  }
					: indexList[sliderIndex].icon
			);
			updateIsActive(indexList[sliderIndex].isActive);
			setSortValue(indexList[sliderIndex].index);
			updateAllData(indexList[sliderIndex]);
		}
		// eslint-disable-next-line
	}, [sliderIndex]);

	const onFinish = (values) => {
		let data = {
			...values,
			icon: icon.path,
			image: image.path,
		};
		let modifiedData = {
			"title.en": data.enTitle,
			"title.ar": data.arTitle,
			image: data.image,
			icon: data.icon,
			index: sortValue,
			utilId: allData?._id,
		};
		updateLoadingSave(true);
		if (modifiedData?.utilId) {
			editUtls(
				modifiedData,
				(success) => {
					updateLoadingSave(false);
					toastSuccess(success.message);
					// getAllUtls();
				},
				(fail) => {
					updateLoadingSave(false);
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		} else {
			addUtilities(
				modifiedData,
				(success) => {
					updateLoadingSave(false);
					toastSuccess(success.message);
					getAllUtls(sliderIndex);
				},
				(fail) => {
					updateLoadingSave(false);
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		}
	};

	const onRadioChange = (e) => {
		setSortValue(e.target.value);
	};
	const handleToggleUtls = (value) => {
		let data = { utilId: allData._id, isActive: value };
		deactivateUtil(
			data,
			(success) => {
				if (success.isSuccess) {
					updateIsActive(value);
				}
			},
			(fail) => {}
		);
	};
	return (
		<div className="d-flex utlsAndMoreContainer">
			<div className="utlsAndMore d-flex flex-1 flex-column">
				<div className="d-flex justify-content-between">
					<Switch
						checked={isActive}
						checkedChildren="ON"
						unCheckedChildren="OFF"
						onChange={handleToggleUtls}
					/>
					<Dropdown>
						<Dropdown.Toggle className="bap-input" id="dropdown-basic">
							Index
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Radio.Group onChange={onRadioChange} value={sortValue}>
								{indexList.map((indexItem, indexNumber) => {
									return (
										<Dropdown.Item key={indexNumber}>
											<Radio value={indexNumber}>
												<div
													onClick={() => {
														updateSliderIndex(indexNumber);
													}}
													className={
														sliderIndex === indexNumber ? "text-underline" : ""
													}>
													{indexNumber + 1}
												</div>
											</Radio>
										</Dropdown.Item>
									);
								})}
							</Radio.Group>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<Form
					onFinish={onFinish}
					name="basic"
					initialValues={{
						enTitle: englishTitle,
						arTitle: arabicTitle,
					}}
					key={allData?.title?.en || indexList.length}>
					<Form.Item
						name="enTitle"
						colon={false}
						required
						requiredMark={false}
						rules={[
							{
								required: true,
								message: "Please, Enter the English title",
							},
						]}>
						<Input
							className="bap-input"
							placeholder={t("bannerTitle")}
							value={englishTitle}
							onChange={(e) => setEnglishTitle(e.target.value)}
							maxLength={50}
						/>
					</Form.Item>

					<Form.Item
						name="arTitle"
						colon={false}
						required
						requiredMark={false}
						rules={[
							{
								required: true,
								message: "Please, Enter the Arabic title",
							},
						]}>
						<Input
							style={{
								direction: "rtl",
							}}
							className="bap-input"
							value={arabicTitle}
							onChange={(e) => setArabicTitle(e.target.value)}
							placeholder={"عنوان الشعار"}
							maxLength={50}
						/>
					</Form.Item>
					<Form.Item
						name="image"
						colon={false}
						valuePropName={image}
						required
						requiredMark={false}
						rules={[
							{
								required: true,
								validator: (_, value) => {
									if (image?.path) {
										return Promise.resolve();
									} else {
										return Promise.reject(
											new Error("Please, upload the image")
										);
									}
								},
							},
						]}>
						<div className="bap-image-show d-flex align-items-center my-2">
							<a
								href={baseUrl + "/" + image?.path}
								target="_blank"
								without
								rel="noreferrer">
								{image?.name}
							</a>
						</div>
						<input
							type="file"
							id="image"
							className="d-none"
							onChange={handleUploadImageIcon}
						/>

						<Button
							loading={loadingImage}
							className="bap-outline-button cursor-pointer m-0 p-0">
							<label htmlFor="image" className="cursor-pointer">
								<span>{t("uploadImage")}</span>
								<img src={UploadIcon} alt="upload" />
							</label>
						</Button>

						{mediaFormatAlert?.place === "image" && (
							<div className="mx-2 text-danger">{mediaFormatAlert.msg}</div>
						)}
					</Form.Item>
					<Form.Item
						name="icon"
						colon={false}
						required
						requiredMark={false}
						rules={[
							{
								required: true,
								validator: (_, value) => {
									if (icon?.path) {
										return Promise.resolve();
									} else {
										return Promise.reject(new Error("Please, upload the Icon"));
									}
								},
							},
						]}>
						<div className="bap-image-show d-flex align-items-center my-2">
							<a
								href={baseUrl + "/" + icon?.path}
								target="_blank"
								without
								rel="noreferrer">
								{icon?.name}
							</a>
						</div>

						<input
							type="file"
							id="icon"
							className="d-none"
							onChange={handleUploadImageIcon}
						/>

						<div className="d-flex">
							<Button
								loading={loadingIcon}
								className="bap-outline-button cursor-pointer m-0 p-0">
								<label htmlFor="icon" className="cursor-pointer">
									<span>{t("uploadIcon")}</span>
									<img src={UploadIcon} alt="upload" />
								</label>
							</Button>
						</div>
						{mediaFormatAlert?.place === "icon" && (
							<div className="mx-2 text-danger">{mediaFormatAlert.msg}</div>
						)}
					</Form.Item>

					<Form.Item>
						<div className="controllings-Buttons">
							<Button
								htmlType="submit"
								type="primary"
								className="bap-primary-button bap-button"
								loading={loadingSave}>
								{t("save")}
							</Button>
							<Button
								onClick={deleteUtls}
								className="bap-primary-button bap-button"
								loading={loadingDelete}
								disabled={indexList.length <= 1}>
								{t("delete")}
							</Button>
						</div>
					</Form.Item>
				</Form>
			</div>
			<div className="plus-container" onClick={addUtls}>
				<img src={plus} alt="plus" />
			</div>
		</div>
	);
}

export default LandingPageUtilitiesAndMore;
