import { useEffect } from "react";
import { getToken } from "services";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";
import i18next from "localization/i18next";

// Elements
import Routes from "modules/Routes";

// styling
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

const queryClient = new QueryClient();

function App() {
  const { lang, dir } = useSelector((state) => state.lang);
  const { authorization } = useSelector((state) => state.auth);

  getToken(authorization?.token);
  useEffect(() => {
    i18next.init({
      lng: lang,
      fallbackLng: lang,
    });
  }, [lang, dir]);

  return (
    <QueryClientProvider client={queryClient}>
      <>
        <Helmet>
          <html lang={lang} dir={dir}></html>
        </Helmet>

        <ToastContainer position="bottom-center" />

        <Routes />
      </>
    </QueryClientProvider>
  );
}

export default App;
