import { PATHS, PATHS_PARAMS } from "constants/PATHS";
import {
	postResource,
	getResource,
	patchResource,
	putResource,
	deleteResource,
} from "services";

export function getAllServices(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getAllServices);
	getResource(path, onSuccess, onFail);
}

export function uploadFile(data, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.uploadQuotaionsFiles);
	postResource(path, data, onSuccess, onFail, true, true);
}

export function addServiceFile(
	{ serviceId, quotationFilePath },
	onSuccess,
	onFail
) {
	const path = PATHS(PATHS_PARAMS.addServiceQuotations);
	const data = {
		serviceId,
		quotationFilePath,
	};
	postResource(path, data, onSuccess, onFail);
}

export function getQuotation(serviceId, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getQuotations, { serviceId });
	getResource(path, onSuccess, onFail);
}

export function getDrawingsRestrictions({ serviceId }, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getDrawingsRestrictions, { serviceId });
	getResource(path, onSuccess, onFail);
}

export function addDrawingsRestrictions(
	{ serviceId, ImageSize, numberOfFiles },
	onSuccess,
	onFail
) {
	const path = PATHS(PATHS_PARAMS.getDrawingsRestrictions);
	const data = {
		serviceId,
		ImageSize,
		numberOfFiles,
	};
	postResource(path, data, onSuccess, onFail);
}

export function updateDrawingsRestrictions(
	{ serviceId, ImageSize, numberOfFiles },
	onSuccess,
	onFail
) {
	const path = PATHS(PATHS_PARAMS.getDrawingsRestrictions, { serviceId });
	const data = {
		ImageSize,
		numberOfFiles,
	};
	patchResource(path, data, onSuccess, onFail);
}

// Multiple Choises Questions
export function getAllQuestions({ serviceID }, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getAllQuestionsAndAnswers, { serviceID });
	getResource(path, onSuccess, onFail);
}

export function getServiceQuestionByQuestionId(
	{ questionId },
	onSuccess,
	onFail
) {
	const path = PATHS(PATHS_PARAMS.getQuestionByQuestionId, {
		questionId,
		isAdmin: true,
	});
	getResource(path, onSuccess, onFail);
}

export function addServiceQuestion(
	{
		serviceQuestion,
		hasOtherAnswer,
		serviceID,
		isPayment,
		isLocation,
		isPro,
		isHomeOwner,
		index,
		isMatch,
		isActive,
		isRequired,
	},
	onSuccess,
	onFail
) {
	const path = PATHS(PATHS_PARAMS.addServiceQuestion);
	const data = {
		"serviceQuestion.en": serviceQuestion.en,
		"serviceQuestion.ar": serviceQuestion.ar,
		hasOtherAnswer,
		serviceID,
		isPayment,
		isLocation,
		isPro,
		isHomeOwner,
		index,
		isMatch,
		isActive,
		isRequired,
	};
	postResource(path, data, onSuccess, onFail);
}

export function editServiceQuestion(
	{
		serviceQuestion,
		questionId,
		serviceID,
		hasOtherAnswer,
		isPro,
		isHomeOwner,
		index,
		isMatch,
		isActive,
		isRequired,
	},
	onSuccess,
	onFail
) {
	const path = PATHS(PATHS_PARAMS.editServiceQuestion, {
		questionId,
		serviceID,
	});
	const data = {
		"serviceQuestion.en": serviceQuestion.en,
		"serviceQuestion.ar": serviceQuestion.ar,
		hasOtherAnswer,
		serviceID,
		isPro,
		isHomeOwner,
		index,
		isMatch,
		isActive,
		isRequired,
	};
	putResource(path, data, onSuccess, onFail);
}

export function deleteServiceQuestion({ questionId }, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.deleteServiceQuestion, { questionId });
	deleteResource(path, onSuccess, onFail);
}

export function addServiceAnswer(answers, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.addServiceAnswer);
	const data = { answers };
	postResource(path, data, onSuccess, onFail);
}

export function editSerivceAnswer(
	{ answerIds, questionId },
	onSuccess,
	onFail
) {
	const path = PATHS(PATHS_PARAMS.editServiceAnswer);
	const data = { answerIds, questionId };
	putResource(path, data, onSuccess, onFail);
}

export function deleteServiceAnswer({ answerId }, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.deleteServiceAnswer, { answerId });
	deleteResource(path, onSuccess, onFail);
}

// Paragraph Questions

export function getParagraphQuestions({ serviceId }, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.addParagraphQuestions, {
		serviceId,
		isAdmin: true,
	});
	getResource(path, onSuccess, onFail);
}

export function addParagraphQuestions(
	{
		paragraphQuestions,
		serviceId,
		index,
		isPro,
		isHomeOwner,
		textMaxSize,
		isRequired,
		isActive,
	},
	onSuccess,
	onFail
) {
	const path = PATHS(PATHS_PARAMS.addParagraphQuestions);
	let data = {
		"paragraphQuestion.en": paragraphQuestions.en,
		"paragraphQuestion.ar": paragraphQuestions.ar,
		serviceId,
		index,
		isPro,
		textMaxSize,
		isHomeOwner,
		isRequired,
		isActive,
	};
	postResource(path, data, onSuccess, onFail);
}

export function editParagraphQuestion(
	{
		paragraphQuestions,
		serviceId,
		questionId,
		index,
		isPro,
		textMaxSize,
		isHomeOwner,
		isRequired,
		isActive,
	},
	onSuccess,
	onFail
) {
	const path = PATHS(PATHS_PARAMS.addParagraphQuestions, {
		serviceId,
		questionId,
	});
	const data = {
		"paragraphQuestion.en": paragraphQuestions.en,
		"paragraphQuestion.ar": paragraphQuestions.ar,
		serviceId,
		index,
		isPro,
		textMaxSize,
		isHomeOwner,
		isRequired,
		isActive,
	};
	putResource(path, data, onSuccess, onFail);
}

export function deleteParagraphQuestion({ questionId }, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.addParagraphQuestions, { questionId });
	deleteResource(path, onSuccess, onFail);
}

export function getTermsAndConditions(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getTermsAndConditions, { isAdmin: true });
	getResource(path, onSuccess, onFail);
}

export function postTermsAndConditions({ en, ar }, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getTermsAndConditions);
	const data = { "termsAndConditions.en": en, "termsAndConditions.ar": ar };
	putResource(path, data, onSuccess, onFail);
}

export function getPrivacyPolice(onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getPrivacyPolice, { isAdmin: true });
	getResource(path, onSuccess, onFail);
}

export function postPrivacyPolice({ ar, en }, onSuccess, onFail) {
	const path = PATHS(PATHS_PARAMS.getPrivacyPolice);
	const data = { "privacyPolicy.en": en, "privacyPolicy.ar": ar };
	putResource(path, data, onSuccess, onFail);
}
