import { PATHS, PATHS_PARAMS } from "constants/PATHS";
import { getResource, putResource } from "services";

export function getAllContactUs(onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.contactUs);
  getResource(path, onSuccess, onFail);
}

export function editContactUsStatus(
  { isResolved, answeredBy, id },
  onSuccess,
  onFail
) {
  const path = PATHS(PATHS_PARAMS.contactUs);
  const data = {
    isResolved,
    answeredBy,
    id,
  };
  putResource(path, data, onSuccess, onFail);
}

export function getRoles(onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.getRoles);
  getResource(path, onSuccess, onFail);
}

export function getAllFeedbackUsers({ accountTypeId }, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.getAllFeedbackUsers, { accountTypeId });
  getResource(path, onSuccess, onFail);
}

export function getUserFeedback({ userId, projectId }, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.feedbackAnswers, { userId, projectId });
  getResource(path, onSuccess, onFail);
}

export function getUserSuggestions({ userId, projectId }, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.userSuggesstion, { userId, projectId });
  getResource(path, onSuccess, onFail);
}

export function getAvarageFeedbacks(accountTypeId, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.avarageFeedbacks, { accountTypeId });
  getResource(path, onSuccess, onFail);
}
