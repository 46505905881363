import { useState, useEffect } from "react";
import { Input, Button, Spin } from "antd";
import { getTermsAndConditions, postTermsAndConditions } from "../network";
import { toastError } from "helpers/toasters";
import { toastSuccess } from "./../../../../helpers/toasters";

const TermsAndConditions = () => {
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [arTermsAndConditions, setArTermsAndConditions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (arTermsAndConditions && termsAndConditions) {
      setIsDisabled(false);
    } else setIsDisabled(true);
  }, [arTermsAndConditions, termsAndConditions]);

  useEffect(() => {
    setDataLoading(true);
    getTermsAndConditions(
      (success) => {
        const {
          termsAndConditions: { en, ar },
        } = success.data;
        setDataLoading(false);
        setTermsAndConditions(en);
        setArTermsAndConditions(ar);
      },
      (fail) => {
        setDataLoading(false);
        if (fail.data.error) {
          toastError(fail.data.error);
        } else {
          toastError();
        }
      }
    );
  }, []);

  function addTermsAndConditions() {
    setIsLoading(true);
    const payload = {
      en: termsAndConditions,
      ar: arTermsAndConditions,
    };
    postTermsAndConditions(
      payload,
      (success) => {
        setIsLoading(false);
        toastSuccess(success.message);
      },
      (fail) => {
        setIsLoading(false);
        if (fail.data.error) {
          toastError(fail.data.error);
        } else {
          toastError();
        }
      }
    );
  }

  return (
    <section className="main-section privacy">
      <header className="main-header">
        <h2>Terms And Conditions</h2>
      </header>

      <div className="main-section__container box-shadow">
        {dataLoading && (
          <div className="spinner__container">
            <Spin className="spinner" />
          </div>
        )}

        <div className="privacy__container ">
          <Input.TextArea
            className="privacy__textarea"
            placeholder="Type terms and conditions..."
            style={{
              height: 250,
            }}
            value={termsAndConditions}
            onChange={(e) => setTermsAndConditions(e.target.value)}
          />
          <Input.TextArea
            className="privacy__textarea"
            dir="rtl"
            placeholder="أكتب الشروط والأحكام ..."
            style={{
              height: 250,
            }}
            value={arTermsAndConditions}
            onChange={(e) => setArTermsAndConditions(e.target.value)}
          />

          <div className="privacy__btn-container">
            <Button
              type="primary"
              onClick={addTermsAndConditions}
              disabled={isDisabled}
              loading={isLoading}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditions;
