import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Navbar from "modules/Shared/Navbar";
import Sidebar from "modules/Shared/Sidebar";
import ROUTES, { homeRoute } from "constants/ROUTES";
import IsActiveAdmin from "./Shared/IsActiveAdmin";

const Home = () => {
	let { pathname } = useLocation();
	let navigate = useNavigate();
	useEffect(() => {
		if (
			pathname.split("/")[1] === ROUTES.HOME &&
			pathname.split("/").length === 2
		)
			navigate("/" + homeRoute);
	}, [navigate, pathname]);

	return (
		<>
			<Navbar />
			<div className="home">
				<div className="home__sidebar">
					<Sidebar />
				</div>
				<div className="home__content">
					<Outlet />
				</div>
			</div>

			<IsActiveAdmin />
		</>
	);
};

export default Home;
