import { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import UploadIcon from "assets/Icons/upload.svg";
import {
	editBanner,
	postBanner,
	getBanner,
	uploadBannerImage,
} from "modules/Pages/PagesModal/network";
import TextArea from "antd/lib/input/TextArea";
import { baseUrl } from "services";
import { toastError, toastSuccess } from "helpers/toasters";

const LandingBanner = () => {
	const [arabicMedia, updateArabicMedia] = useState(null);
	const [englishMedia, updateEnglishMedia] = useState(null);
	const [arabicTitle, setArabicTitle] = useState("");
	const [englishTitle, setEnglishTitle] = useState("");
	const [arabicDescription, setArabicDescription] = useState("");
	const [englishDescription, setEnglishDescription] = useState("");
	const [isVideo, updateIsVideo] = useState(null);
	const [loadingSave, setLoadingSave] = useState(false);
	const [mediaFormatAlert, updateMediaFormatAlert] = useState(false);
	const [loadingMedia, updateLoadingMedia] = useState({ en: false, ar: false });
	const [allData, updateAllData] = useState(null);
	const { t } = useTranslation();

	useEffect(() => {
		getBanner(
			(success) => {
				let data = success.data[0];
				let englishMedia = {};
				let arabicMedia = {};
				englishMedia.path = data.image.en;
				arabicMedia.path = data.image.ar;
				if (data.image.en.includes("-image-")) {
					updateIsVideo(false);
					englishMedia.name = data.image.en.split("-image-")[1];
					arabicMedia.name = data.image.ar.split("-image-")[1];
				} else {
					updateIsVideo(true);
					englishMedia.name = data.image.en.split("-video-")[1];
					arabicMedia.name = data.image.ar.split("-video-")[1];
				}
				setEnglishTitle(data.title.en);
				setArabicTitle(data.title.ar);
				setEnglishDescription(data.text.en);
				setArabicDescription(data.text.ar);
				updateEnglishMedia(englishMedia);
				updateArabicMedia(arabicMedia);
				updateAllData(data);
			},
			(fail) => {}
		);
	}, []);

	const handleUploadBannerImage = (e) => {
		const isImageOrVideo =
			e.target.files[0].type.includes("image") ||
			e.target.files[0].type.includes("video");

		const isBothHaveDiffFormat =
			isVideo !== null &&
			((isVideo && e.target.files[0].type.includes("image")) ||
				(!isVideo && e.target.files[0].type.includes("video")));
		if (!isImageOrVideo) {
			updateMediaFormatAlert({
				place: e.target.id,
				msg: "* Please, upload image or video files only",
			});
		} else {
			if (isBothHaveDiffFormat) {
				updateArabicMedia(null);
				updateEnglishMedia(null);
			}
			updateMediaFormatAlert(null);
			if (e.target.id === "arBannerFile") {
				let loadingMediaVar = { ...loadingMedia };
				loadingMediaVar.ar = true;
				updateLoadingMedia(loadingMediaVar);
			} else {
				let loadingMediaVar = { ...loadingMedia };
				loadingMediaVar.en = true;
				updateLoadingMedia(loadingMediaVar);
			}
			let imageDataFile = new FormData();
			imageDataFile.append(
				"image",
				e.target.files[0],
				`-${e.target.files[0].type.split("/")[0]}-${e.target.files[0].name}`
			);
			uploadBannerImage(
				imageDataFile,
				(success) => {
					if (success.isSuccess) {
						updateIsVideo(e.target.files[0].type.includes("video"));

						if (e.target.id === "arBannerFile") {
							let fileData = Object.assign(e.target.files[0]);
							fileData.path = success.data.path;
							updateArabicMedia(fileData);
						} else {
							let fileData = Object.assign(e.target.files[0]);
							fileData.path = success.data.path;
							updateEnglishMedia(fileData);
						}
					}
					let loadingMediaVar = { ...loadingMedia };
					loadingMediaVar.en = false;
					loadingMediaVar.ar = false;
					updateLoadingMedia(loadingMediaVar);
				},
				(fail) => {
					let loadingMediaVar = { ...loadingMedia };
					loadingMediaVar.en = false;
					loadingMediaVar.ar = false;
					updateLoadingMedia(loadingMediaVar);
				}
			);
		}
	};
	const onFinish = (values) => {
		let data = {
			...values,
			arMedia: arabicMedia.path,
			enMedia: englishMedia.path,
		};
		let modifiedData = {
			"title.en": data.enTitle,
			"title.ar": data.arTitle,
			"text.en": data.enDescription,
			"text.ar": data.arDescription,
			"image.en": data.enMedia,
			"image.ar": data.arMedia,
			isVideo,
		};

		setLoadingSave(true);
		if (allData) {
			editBanner(
				modifiedData,
				(success) => {
					setLoadingSave(false);
					toastSuccess(success.message);
				},
				(fail) => {
					setLoadingSave(false);
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		} else {
			postBanner(
				modifiedData,
				(success) => {
					setLoadingSave(false);
					toastSuccess(success.message);
				},
				(fail) => {
					setLoadingSave(false);
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		}
	};

	return (
		<div className="banner">
			<header className="header">
				<h6>{t("landingPageBanner")}</h6>
			</header>

			<Form
				onFinish={onFinish}
				name="basic"
				initialValues={{
					enTitle: englishTitle,
					arTitle: arabicTitle,
					enDescription: englishDescription,
					arDescription: arabicDescription,
				}}
				key={allData}
			>
				<Form.Item
					name="enMedia"
					colon={false}
					valuePropName={englishMedia}
					required
					requiredMark={false}
					rules={[
						{
							required: true,
							validator: (_, value) => {
								if (englishMedia) {
									return Promise.resolve();
								} else {
									return Promise.reject(
										new Error("Please, upload the english media")
									);
								}
							},
						},
					]}
				>
					<div className="bap-image-show d-flex align-items-center my-2">
						<a
							href={baseUrl + "/" + englishMedia?.path}
							target="_blank"
							without
							rel="noreferrer"
						>
							{englishMedia?.name}
						</a>
					</div>
					<input
						type="file"
						id="enBannerFile"
						className="d-none"
						onChange={handleUploadBannerImage}
					/>

					<Button
						loading={loadingMedia.en}
						className="bap-outline-button cursor-pointer m-0 p-0"
					>
						<label htmlFor="enBannerFile" className="cursor-pointer">
							<span>{t("uploadEnImage")}</span>
							<img src={UploadIcon} alt="upload" />
						</label>
					</Button>
					{mediaFormatAlert?.place === "enBannerFile" && (
						<div className="mx-2 text-danger">{mediaFormatAlert.msg}</div>
					)}
				</Form.Item>
				<Form.Item
					name="arMedia"
					colon={false}
					required
					requiredMark={false}
					rules={[
						{
							required: true,
							validator: (_, value) => {
								if (arabicMedia) {
									return Promise.resolve();
								} else {
									return Promise.reject(
										new Error("Please, upload the arabic media")
									);
								}
							},
						},
					]}
				>
					<div className="bap-image-show d-flex align-items-center my-2">
						<a
							href={baseUrl + "/" + arabicMedia?.path}
							target="_blank"
							without
							rel="noreferrer"
						>
							{arabicMedia?.name}
						</a>
					</div>
					<input
						type="file"
						id="arBannerFile"
						className="d-none"
						onChange={handleUploadBannerImage}
					/>
					<div className="d-flex justify-content-end">
						<Button
							loading={loadingMedia.ar}
							className="bap-outline-button cursor-pointer m-0 p-0"
						>
							<label htmlFor="arBannerFile" className="cursor-pointer">
								<span>{t("uploadArImage")}</span>
								<img src={UploadIcon} alt="upload" />
							</label>
						</Button>
					</div>
					{mediaFormatAlert?.place === "arBannerFile" && (
						<div className="mx-2 text-danger">{mediaFormatAlert.msg}</div>
					)}
				</Form.Item>

				<Form.Item
					name="enTitle"
					colon={false}
					required
					requiredMark={false}
					rules={[
						{
							required: true,
							message: "Please, enter the english title",
						},
					]}
				>
					<Input
						className="bap-input"
						placeholder={t("bannerTitle")}
						value={englishTitle}
						onChange={(e) => setEnglishTitle(e.target.value)}
					/>
				</Form.Item>
				<Form.Item
					name="arTitle"
					colon={false}
					required
					requiredMark={false}
					rules={[
						{
							required: true,
							message: "Please, enter the arabic title",
						},
					]}
				>
					<Input
						style={{
							direction: "rtl",
						}}
						className="bap-input"
						value={arabicTitle}
						onChange={(e) => setArabicTitle(e.target.value)}
						placeholder={"عنوان الشعار"}
					/>
				</Form.Item>
				<div className="mb-2">
					Note:
					<br />
					→ Using three asterisks (***) before and after the word means this
					words colored in the main color.
					<br />→ Using (/n) means inserting new line.
					<br />→ Using one asterisk (*) before the word means 5 spaces.
				</div>
				<Form.Item
					name="enDescription"
					colon={false}
					required
					requiredMark={false}
					rules={[
						{
							required: true,
							message: "Please, enter the english description",
						},
					]}
				>
					<TextArea
						className="bap-textarea"
						value={englishDescription}
						onChange={(e) => setEnglishDescription(e.target.value)}
						placeholder={t("bannerText")}
						maxLength={200}
					/>
				</Form.Item>
				<Form.Item
					name="arDescription"
					colon={false}
					required
					requiredMark={false}
					rules={[
						{
							required: true,
							message: "Please, enter the arabic title",
						},
					]}
				>
					<TextArea
						style={{
							direction: "rtl",
						}}
						className="bap-textarea"
						value={arabicDescription}
						onChange={(e) => setArabicDescription(e.target.value)}
						placeholder={"عنوان الشعار"}
						maxLength={200}
					/>
				</Form.Item>
				<Form.Item>
					<div className="controllings-Buttons">
						<Button
							htmlType="submit"
							type="primary"
							className="bap-primary-button bap-button"
							loading={loadingSave}
						>
							{t("save")}
						</Button>

						{/* <Button
								className="bap-primary-button bap-button"
								loading={loadingDelete}>
								{t("delete")}
				</Button> */}
					</div>
				</Form.Item>
			</Form>
		</div>
	);
};

export default LandingBanner;
